import { NavDropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { handleNavItemSelect } from "../../reducers/app-common/actions";
import { signOut } from "aws-amplify/auth";

const AdminControlsDropDown = () => {
  const appCommonSelector = (state) => state.appCommon;
  const appCommonData = useSelector(appCommonSelector);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      dispatch(handleNavItemSelect(!appCommonData.isNavbarExpanded));
      await signOut();
    } catch (error) {
      console.log("Error occurred while signing out : " + error.message);
    } finally {
      navigate("/");
    }
  };

  return (
    <NavDropdown
      title={
        appCommonData.appLanguage === "English"
          ? "Admin Controls"
          : "நிர்வாக கட்டுப்பாடுகள்"
      }
      className="my-profile-dropdown"
    >
      <NavDropdown.Item
        as={RouterLink}
        to="/account"
        onClick={(e) => {
          dispatch(handleNavItemSelect(!appCommonData.isNavbarExpanded));
        }}
      >
        {appCommonData.appLanguage === "English" ? "My Account" : "எனது கணக்கு"}
      </NavDropdown.Item>
      <NavDropdown.Item
        as={RouterLink}
        to="/changePassword"
        onClick={(e) => {
          dispatch(handleNavItemSelect(!appCommonData.isNavbarExpanded));
        }}
      >
        {appCommonData.appLanguage === "English"
          ? "Change Password"
          : "கடவுச்சொல்லை மாற்றம்"}
      </NavDropdown.Item>
      <NavDropdown.Divider />
      <NavDropdown.Item
        as={RouterLink}
        to="/taxrates"
        onClick={(e) => {
          dispatch(handleNavItemSelect(!appCommonData.isNavbarExpanded));
        }}
      >
        {appCommonData.appLanguage === "English"
          ? "Manage Tax Rates"
          : "வரி விகிதங்களை நிர்வகிக்க"}
      </NavDropdown.Item>
      <NavDropdown.Item
        as={RouterLink}
        to="/cdrates"
        onClick={(e) => {
          dispatch(handleNavItemSelect(!appCommonData.isNavbarExpanded));
        }}
      >
        {appCommonData.appLanguage === "English"
          ? "Manage Deposit Rates"
          : "வைப்பு விகிதங்களை நிர்வகிக்க"}
      </NavDropdown.Item>
      <NavDropdown.Item
        as={RouterLink}
        to="/promocodes"
        onClick={(e) => {
          dispatch(handleNavItemSelect(!appCommonData.isNavbarExpanded));
        }}
      >
        {appCommonData.appLanguage === "English"
          ? "Manage Promocodes"
          : "விளம்பர குறியீடுகள்"}
      </NavDropdown.Item>
      <NavDropdown.Divider />
      <NavDropdown.Item onClick={handleLogout}>
        {appCommonData.appLanguage === "English" ? "Logout" : "வெளியேறு"}
      </NavDropdown.Item>
    </NavDropdown>
  );
};

export default AdminControlsDropDown;
