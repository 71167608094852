import React, { useEffect, useState } from "react";
import {
  bookACookContent,
  bookACookContentInTamil,
  bookACookHeader,
  bookACookHeaderInTamil,
  diningContent,
  diningContentInTamil,
  diningHeader,
  diningHeaderInTamil,
  spaciousDiningContent,
  spaciousDiningContentInTamil,
  spaciousDiningHeader,
  spaciousDiningHeaderInTamil,
} from "../../content";
import Photo1 from "./../../assets/images/home-slider/dining.JPG";
import Photo2 from "./../../assets/images/home-slider/courtyard.JPG";
import { useSelector } from "react-redux";

const Dining = () => {
  const appCommonSelector = (state) => state.appCommon;
  const appCommonData = useSelector(appCommonSelector);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  return (
    <div className={appCommonData.appLanguage} id="dining">
      <h1>
        {appCommonData.appLanguage === "English"
          ? diningHeader
          : diningHeaderInTamil}
      </h1>
      <p>
        {appCommonData.appLanguage === "English"
          ? diningContent
          : diningContentInTamil}
      </p>
      <div className="container">
        <div
          className={`image slide-in-from-left-container ${
            isVisible ? "visible" : ""
          }`}
        >
          <img src={Photo1} width="100%" alt="spacious-dining" />
        </div>
        <div className="content">
          <h2>
            {appCommonData.appLanguage === "English"
              ? spaciousDiningHeader
              : spaciousDiningHeaderInTamil}
          </h2>
          {/*<p>
            {appCommonData.appLanguage === "English"
              ? spaciousDiningContent
              : spaciousDiningContentInTamil}
          </p>*/}
        </div>
      </div>
      <div className="container reverse-order">
        <div className="content">
          <h2>
            {appCommonData.appLanguage === "English"
              ? bookACookHeader
              : bookACookHeaderInTamil}
          </h2>
          {/*<p>
            {appCommonData.appLanguage === "English"
              ? bookACookContent
              : bookACookContentInTamil}
          </p>*/}
        </div>
        <div
          className={`image slide-in-from-right-container ${
            isVisible ? "visible" : ""
          }`}
        >
          <img src={Photo2} width="100%" alt="book-a-cook" />
        </div>
      </div>
    </div>
  );
};

export default Dining;
