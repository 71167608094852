import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./css/PhotoSlider.css";

const PhotoSlider = ({ images }) => {
  const settings = {
    adaptiveHeight: true,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: false,
  };

  return (
    <div className="photo-slider">
      <Slider {...settings}>
        {images.map((image, index) => (
          <div key={index}>
            <img className="slider-images" src={image} alt={`Slide ${index}`} />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default PhotoSlider;
