export function updateAppLanguage(value: string) {
  return {
    type: "@@APP_COMMON/UPDATE_APP_LANGUAGE",
    payload: value,
  };
}

export function handleNavItemSelect(value: boolean) {
  return {
    type: "@@APP_COMMON/HANDLE_NAV_ITEM_SELECT",
    payload: value,
  };
}
