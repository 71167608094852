import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Loader, useAuthenticator } from "@aws-amplify/ui-react";
import "./css/Login.css";
import {
  autoSignIn,
  confirmSignUp,
  fetchAuthSession,
  resendSignUpCode,
  signIn,
  signUp,
} from "aws-amplify/auth";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import "@aws-amplify/ui-react/styles.css";
import { PhoneNumberUtil } from "google-libphonenumber";
import LogoSection from "../commons/LogoSection";
import { Button } from "react-bootstrap";
import { updateUserRole } from "../../reducers/user/actions";

const Login = () => {
  const appCommonSelector = (state) => state.appCommon;
  const appCommonData = useSelector(appCommonSelector);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { authStatus } = useAuthenticator((context) => [context.authStatus]);

  useEffect(() => {
    if (authStatus && authStatus === "authenticated") {
      console.log("AuthStatus is : " + authStatus);
      navigate("/");
    }
  }, [navigate, authStatus]);

  const [isLoading, setLoading] = useState(false);
  const [isConfirmUserDivOpen, setConfirmUserDivOpen] = useState(false);

  const [isLogin, setIsLogin] = useState(true);
  const [isEmail, setIsEmail] = useState(true);

  //Constants
  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  const nameRegex = /^[a-zA-Z ]*$/;

  //Login Hooks
  const [loginEmail, setLoginEmail] = useState("");
  const [loginPhone, setLoginPhone] = useState("");
  const [loginPassword, setLoginPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loginErrorMessage, setLoginErrorMessage] = useState("");

  //Register Form Hooks
  const [registerEmail, setRegisterEmail] = useState("");
  const [registerPassword, setRegisterPassword] = useState("");
  const [registerConfirmPassword, setRegisterConfirmPassword] = useState("");
  const [registerName, setRegisterName] = useState("");
  const [registerBirthDate, setRegisterBirthDate] = useState("");
  const [registerMobileNo, setRegisterMobileNo] = useState("");
  const [signUpConfirmationCode, setSignUpConfirmationCode] = useState("");

  const setLoginButtonActive = () => {
    resetInputs();
    setIsLogin(true);
  };

  const setRegisterButtonActive = () => {
    resetInputs();
    setIsLogin(false);
  };

  const resetInputs = () => {
    setIsEmail(true);
    //Login
    setLoginEmail("");
    setLoginPassword("");
    setLoginPhone("");
    setLoginErrorMessage("");

    //Register
    setRegisterEmail("");
    setRegisterPassword("");
    setRegisterConfirmPassword("");
    setRegisterName("");
    setRegisterBirthDate("");
    setRegisterMobileNo("");

    //Common
    setShowPassword(false);
  };

  const setEmailButtonActive = () => {
    resetInputs();
    setIsEmail(true);
  };

  const setMobileButtonActive = () => {
    resetInputs();
    setIsEmail(false);
  };

  const isPhoneValid = (phone) => {
    try {
      const phoneUtil = PhoneNumberUtil.getInstance();
      return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
    } catch (error) {
      return false;
    }
  };

  const isValidLoginInputs = () => {
    setLoginErrorMessage("");
    if (isEmail) {
      if (!loginEmail) {
        setLoginErrorMessage("Email address cannot be empty.");
        return false;
      }
      if (!emailRegex.test(loginEmail)) {
        setLoginErrorMessage("Invalid email address.");
        return false;
      }
    } else {
      console.log("Phone Number : " + loginPhone);
      if (!loginPhone) {
        setLoginErrorMessage("Phone number cannot be empty.");
        return false;
      }
      if (!isPhoneValid(loginPhone)) {
        setLoginErrorMessage("Invalid Phone Number");
        return false;
      }
    }
    if (!loginPassword) {
      setLoginErrorMessage("Password cannot be empty.");
      return false;
    }
    return true;
  };

  const handleSignIn = async (e, username, password) => {
    e.preventDefault();
    if (isValidLoginInputs()) {
      try {
        setLoading(true);
        const { isSignedIn } = await signIn({ username, password });

        if (isSignedIn) {
          handleUserRoleAssignment();
          navigate("/");
        }
      } catch (error) {
        setLoginErrorMessage(error.message);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleUserRoleAssignment = async () => {
    try {
      const { tokens } = await fetchAuthSession();
      const userGroups = tokens.accessToken.payload["cognito:groups"];
      if (
        userGroups &&
        (userGroups.includes("beta-admin") || userGroups.includes("admin"))
      ) {
        dispatch(updateUserRole("Admin"));
        console.log("user belong to " + userGroups);
      } else {
        dispatch(updateUserRole("Guest"));
        console.log("user does not belong to any user group ");
      }
    } catch (error) {
      console.error("Error updating user role", error);
    }
  };

  const isValidRegisterInputs = () => {
    setLoginErrorMessage("");
    if (isEmail) {
      if (!registerEmail) {
        setLoginErrorMessage("Email address cannot be empty.");
        return false;
      }
      if (!emailRegex.test(registerEmail)) {
        setLoginErrorMessage("Invalid email address.");
        return false;
      }
    } else {
    }
    if (registerPassword !== registerConfirmPassword) {
      setLoginErrorMessage("Passwords does not match.");
      return false;
    }
    if (registerPassword.length < 8 || registerPassword.length > 16) {
      setLoginErrorMessage(
        "Password length should be between 8 and 16 characters."
      );
      return false;
    }
    if (!registerName) {
      setLoginErrorMessage("Name cannot be empty.");
      return false;
    }
    if (registerName.length > 50) {
      setLoginErrorMessage(
        "The length of the name should be less than 50 characters."
      );
      return false;
    }
    if (!nameRegex.test(registerName)) {
      setLoginErrorMessage("Name should contain only alphabets and spaces.");
      return false;
    }
    if (!registerBirthDate) {
      setLoginErrorMessage("Date of birth cannot be empty");
    }

    return true;
  };

  const handleSignUp = async () => {
    //handle sign up code.
    try {
      setLoading(true);
      if (isValidRegisterInputs()) {
        const username = isEmail ? registerEmail : registerMobileNo;
        const password = registerPassword;
        const name = registerName;
        const birthdate = registerBirthDate;
        const { isSignUpComplete, userId, nextStep } = await signUp({
          username,
          password,
          options: {
            userAttributes: {
              name,
              birthdate,
            },
            autoSignIn: true,
          },
        });
        console.log("isSignUpComplete : " + isSignUpComplete);
        console.log("userId : " + userId);
        console.log("nextStep : " + nextStep);
        if (!isSignUpComplete) {
          setConfirmUserDivOpen(true);
        }
      }
    } catch (error) {
      setLoginErrorMessage(
        "Error occurred while signing up : " + error.message
      );
    } finally {
      setLoading(false);
    }
  };

  const handleConfirmSignUp = async () => {
    try {
      setLoading(true);
      const username = isEmail ? registerEmail : registerMobileNo;
      const confirmationCode = signUpConfirmationCode;
      const { isSignUpComplete, nextStep } = await confirmSignUp({
        username,
        confirmationCode,
      });
      if (isSignUpComplete) {
        setLoginErrorMessage("User Registration Successful!");
        const signInOutput = await autoSignIn();
        handleUserRoleAssignment();
        navigate("/");
      }
    } catch (error) {
      setLoginErrorMessage("Error confirming sign up : ", error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleResendConfirmationCode = async () => {
    try {
      const username = isEmail ? registerEmail : registerMobileNo;
      const { deliveryMedium } = await resendSignUpCode({ username });
      setLoginErrorMessage("Confirmation Code is resent via " + deliveryMedium);
    } catch (error) {
      setLoginErrorMessage("Code resent");
    }
  };

  const handleShowPasswordChange = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="login-top-container">
      <div className="login-register-container">
        <div className="toggle-buttons">
          <button
            className={isLogin ? "active" : ""}
            onClick={setLoginButtonActive}
            type="button"
          >
            {appCommonData.appLanguage === "English" ? "Login" : "உள்நுழை"}
          </button>
          <button
            className={!isLogin ? "active" : ""}
            onClick={setRegisterButtonActive}
            type="button"
          >
            {appCommonData.appLanguage === "English"
              ? "Register"
              : "பதிவு செய்"}
          </button>
        </div>
        {isLogin ? (
          <form className="login-form">
            <div className="login-register-choice-container">
              <LogoSection />
              <h6>
                {appCommonData.appLanguage === "English"
                  ? "Login using "
                  : "உள்நுழைய பயன்படுத்த வேண்டியது ?"}
              </h6>
              <div className="login-choice-buttons">
                <button
                  className={isEmail ? "active" : ""}
                  id="login-choice-button"
                  onClick={setEmailButtonActive}
                  type="button"
                >
                  {appCommonData.appLanguage === "English"
                    ? "Email ID"
                    : "மின்னஞ்சல் முகவரி"}
                </button>
                <button
                  className={!isEmail ? "active" : ""}
                  id="login-choice-button"
                  onClick={setMobileButtonActive}
                  type="button"
                >
                  {appCommonData.appLanguage === "English"
                    ? "Mobile No"
                    : "அலைபேசி எண்"}
                </button>
              </div>
            </div>
            <div className="form-group">
              <label>
                {isEmail
                  ? appCommonData.appLanguage === "English"
                    ? "Email ID"
                    : "மின்னஞ்சல் முகவரி"
                  : appCommonData.appLanguage === "English"
                  ? "Mobile No"
                  : "அலைபேசி எண்"}
                {isEmail ? (
                  <input
                    type="email"
                    value={loginEmail}
                    onChange={(e) => setLoginEmail(e.target.value)}
                  />
                ) : (
                  <PhoneInput
                    defaultCountry="in"
                    value={loginPhone}
                    onChange={(phone) => setLoginPhone(phone)}
                  />
                )}
              </label>
              <label>
                {appCommonData.appLanguage === "English"
                  ? "Password"
                  : "கடவுச்சொல்"}
                <input
                  type={showPassword ? "text" : "password"}
                  value={loginPassword}
                  onChange={(e) => setLoginPassword(e.target.value)}
                />
              </label>
              <label>
                <input
                  type="checkbox"
                  value={showPassword}
                  checked={showPassword}
                  onChange={handleShowPasswordChange}
                />
                {appCommonData.appLanguage === "English"
                  ? " Show password"
                  : " கடவுச்சொல்லை காட்டவும்"}
              </label>
              <br />
              <Button
                type="button"
                variant="light"
                as={Link}
                to="/resetPassword"
              >
                {appCommonData.appLanguage === "English"
                  ? "Forgot password ?"
                  : "கடவுச்சொல்லை மறந்துவிட்டீர்களா ?"}
              </Button>
            </div>
            <button
              type="button"
              onClick={(e) => {
                handleSignIn(
                  e,
                  isEmail ? loginEmail : loginPhone,
                  loginPassword
                );
              }}
            >
              {appCommonData.appLanguage === "English" ? "Login" : "உள்நுழை"}
            </button>
            {isLoading && (
              <div>
                <Loader variation="linear" filledColor="brown" />
              </div>
            )}
            {loginErrorMessage && (
              <div className="error-messages-container">
                <h6>{loginErrorMessage}</h6>
              </div>
            )}
          </form>
        ) : (
          <form className="register-form">
            <div className="login-register-choice-container">
              <LogoSection />
              <h6>
                {appCommonData.appLanguage === "English"
                  ? "User Registration"
                  : "பயனர் பதிவு"}
              </h6>
              <div className="login-choice-buttons">
                <button
                  className={isEmail ? "active" : ""}
                  id="login-choice-button"
                  onClick={setEmailButtonActive}
                  type="button"
                >
                  {appCommonData.appLanguage === "English"
                    ? "Email ID"
                    : "மின்னஞ்சல் முகவரி"}
                </button>
                <button
                  className={!isEmail ? "active" : ""}
                  id="login-choice-button"
                  onClick={setMobileButtonActive}
                  type="button"
                >
                  {appCommonData.appLanguage === "English"
                    ? "Mobile No"
                    : "அலைபேசி எண்"}
                </button>
              </div>
            </div>
            <label>
              {isEmail
                ? appCommonData.appLanguage === "English"
                  ? "Email ID (mandatory)"
                  : "மின்னஞ்சல் முகவரி (கட்டாயம்)"
                : appCommonData.appLanguage === "English"
                ? "Mobile No"
                : "அலைபேசி எண்"}
              {isEmail ? (
                <input
                  type="email"
                  value={registerEmail}
                  onChange={(e) => setRegisterEmail(e.target.value)}
                />
              ) : (
                <PhoneInput
                  defaultCountry="in"
                  value={registerMobileNo}
                  onChange={(phone) => setRegisterMobileNo(phone)}
                />
              )}
            </label>
            <label>
              {appCommonData.appLanguage === "English"
                ? "Password"
                : "கடவுச்சொல்"}
              <input
                type={showPassword ? "text" : "password"}
                value={registerPassword}
                onChange={(e) => setRegisterPassword(e.target.value)}
              />
            </label>
            <label>
              {appCommonData.appLanguage === "English"
                ? "Confirm password"
                : "கடவுச்சொல்லை உறுதிப்படுத்தவும்"}
              <input
                type={showPassword ? "text" : "password"}
                value={registerConfirmPassword}
                onChange={(e) => setRegisterConfirmPassword(e.target.value)}
              />
            </label>
            <label>
              <input
                type="checkbox"
                value={showPassword}
                checked={showPassword}
                onChange={handleShowPasswordChange}
              />
              {appCommonData.appLanguage === "English"
                ? " Show password"
                : " கடவுச்சொல்லை காட்டவும்"}
            </label>
            <hr />
            <label>
              {appCommonData.appLanguage === "English" ? "Name" : "பெயர்"}
              <input
                type="text"
                value={registerName}
                onChange={(e) => setRegisterName(e.target.value)}
              />
            </label>
            <label>
              {appCommonData.appLanguage === "English"
                ? "Date of Birth"
                : "பிறந்த தேதி"}
              <input
                type="date"
                value={registerBirthDate}
                onChange={(e) => setRegisterBirthDate(e.target.value)}
              />
            </label>
            {!isConfirmUserDivOpen && (
              <button type="button" onClick={handleSignUp}>
                {appCommonData.appLanguage === "English"
                  ? "Register"
                  : "பதிவு செய்"}
              </button>
            )}
            {isConfirmUserDivOpen && (
              <div className="confirm-user-div">
                <hr />
                <label>
                  {isEmail
                    ? appCommonData.appLanguage === "English"
                      ? "Please enter the verification code sent to your email address."
                      : "உங்கள் மின்னஞ்சல் முகவரிக்கு அனுப்பப்பட்ட சரிபார்ப்புக் குறியீட்டை உள்ளிடவும்."
                    : appCommonData.appLanguage === "English"
                    ? "Please enter the verification code sent to your phone number."
                    : "உங்கள் அலைபேசி எண்ணுக்கு அனுப்பப்பட்ட சரிபார்ப்புக் குறியீட்டை உள்ளிடவும்."}
                  <input
                    type="text"
                    value={signUpConfirmationCode}
                    onChange={(e) => setSignUpConfirmationCode(e.target.value)}
                  />
                </label>
                <button
                  type="button"
                  variant="custom"
                  id="btn-custom-secondary"
                  onClick={handleResendConfirmationCode}
                >
                  {appCommonData.appLanguage === "English"
                    ? "Resend"
                    : "மீண்டும் அனுப்பு"}
                </button>
                <button
                  type="button"
                  variant="custom"
                  id="btn-custom-primary"
                  onClick={handleConfirmSignUp}
                  disabled={!signUpConfirmationCode}
                >
                  {appCommonData.appLanguage === "English"
                    ? "Verify"
                    : "சரிபார்க்கவும்"}
                </button>
              </div>
            )}
            {isLoading && (
              <div>
                <Loader variation="linear" filledColor="brown" />
              </div>
            )}
            {loginErrorMessage && (
              <div className="error-messages-container">
                <h6>{loginErrorMessage}</h6>
              </div>
            )}
          </form>
        )}
      </div>
    </div>
  );
};

export default Login;
