import React, { useState } from "react";
import "./css/ForgotPassword.css";
import LogoSection from "../commons/LogoSection";
import { useSelector } from "react-redux";
import { Loader } from "@aws-amplify/ui-react";
import { confirmResetPassword, resetPassword } from "aws-amplify/auth";
import { useNavigate } from "react-router-dom";

const ForgotPassword = () => {
  const appCommonSelector = (state) => state.appCommon;
  const appCommonData = useSelector(appCommonSelector);
  const navigate = useNavigate();

  //reset password inputs
  const [email, setEmail] = useState("");
  const [confirmationCode, setConfirmationCode] = useState("");
  const [newPassword, setPassword] = useState("");
  const [confirmPassword, setConfrmPassword] = useState("");

  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  const sixDigitRegex = /\d{6}/;

  //Commons
  const [isLoading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isConfirmCodeSent, setConfirmCodeSent] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleShowPasswordChange = () => {
    setShowPassword(!showPassword);
  };

  const isValidResetPasswordInputs = () => {
    if (!email) {
      setErrorMessage("Email address cannot be empty.");
      return false;
    }
    if (!emailRegex.test(email)) {
      setErrorMessage("Invalid email address.");
      return false;
    }
    return true;
  };

  const handleResetPassword = async () => {
    try {
      setErrorMessage("");
      setLoading(true);
      if (isValidResetPasswordInputs()) {
        const username = email;
        const output = await resetPassword({ username });
        const { nextStep } = output;
        switch (nextStep.resetPasswordStep) {
          case "CONFIRM_RESET_PASSWORD_WITH_CODE":
            const codeDeliveryDetails = nextStep.codeDeliveryDetails;
            setErrorMessage(
              `Confirmation code was sent to ${codeDeliveryDetails.destination}`
            );
            setConfirmCodeSent(true);
            break;
          case "DONE":
            break;
        }
      }
    } catch (error) {
      setErrorMessage(error.message);
    } finally {
      setLoading(false);
    }
  };

  const isValidConfirmResetPasswordInputs = () => {
    if (!confirmationCode) {
      setErrorMessage("Confirmation code cannot be empty.");
      return false;
    }
    if (!sixDigitRegex.test(confirmationCode)) {
      setErrorMessage("Confirmation code must be a 6 digit number");
      return false;
    }
    if (newPassword !== confirmPassword) {
      setErrorMessage("Passwords does not match.");
      return false;
    }
    if (newPassword.length < 8 || newPassword.length > 16) {
      setErrorMessage("Password length should be between 8 and 16 characters.");
      return false;
    }
    return true;
  };

  const delay = (ms) => new Promise((res) => setTimeout(res, ms));

  const handleConfirmResetPassword = async () => {
    try {
      setErrorMessage("");
      setLoading(true);
      if (isValidConfirmResetPasswordInputs()) {
        const username = email;
        await confirmResetPassword({ username, confirmationCode, newPassword });
        setErrorMessage(
          "Password reset successful. Redirecting to login in 3 seconds.."
        );
        await delay(3000);
        navigate("/login");
      }
    } catch (error) {
      setErrorMessage(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="forgot-password-top-container">
      <div className="forgot-password-main-container">
        <LogoSection />
        <h6
          style={{
            fontFamily: "Merriweather",
            margin: "2%",
            textAlign: "center",
          }}
        >
          {appCommonData.appLanguage === "English"
            ? "Reset Password"
            : "கடவுச்சொல் மீட்டமைத்தல்"}
        </h6>
        <label>
          {appCommonData.appLanguage === "English"
            ? "Email ID"
            : "மின்னஞ்சல் முகவரி"}
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            disabled={isConfirmCodeSent ? true : false}
          />
        </label>
        {!isConfirmCodeSent && (
          <button type="button" onClick={handleResetPassword}>
            {appCommonData.appLanguage === "English"
              ? "Reset Password"
              : "கடவுச்சொல்லை மீட்டமை"}
          </button>
        )}
        {isConfirmCodeSent && (
          <>
            <hr />
            <label>
              {appCommonData.appLanguage === "English"
                ? "Confirmation code"
                : "உறுதிப்படுத்தல் குறியீடு"}
              <input
                type="text"
                value={confirmationCode}
                onChange={(e) => setConfirmationCode(e.target.value)}
              />
            </label>
            <label>
              {appCommonData.appLanguage === "English"
                ? "Password"
                : "கடவுச்சொல்"}
              <input
                type={showPassword ? "text" : "password"}
                value={newPassword}
                onChange={(e) => setPassword(e.target.value)}
              />
            </label>
            <label>
              {appCommonData.appLanguage === "English"
                ? "Confirm password"
                : "கடவுச்சொல்லை உறுதிப்படுத்தவும்"}
              <input
                type={showPassword ? "text" : "password"}
                value={confirmPassword}
                onChange={(e) => setConfrmPassword(e.target.value)}
              />
            </label>
            <label>
              <input
                type="checkbox"
                value={showPassword}
                checked={showPassword}
                onChange={handleShowPasswordChange}
              />
              {appCommonData.appLanguage === "English"
                ? " Show password"
                : " கடவுச்சொல்லை காட்டவும்"}
            </label>
            <button type="button" onClick={handleConfirmResetPassword}>
              {appCommonData.appLanguage === "English"
                ? "Reset Password"
                : "கடவுச்சொல்லை மீட்டமை"}
            </button>
          </>
        )}

        {errorMessage && (
          <div className="error-messages-container">
            <h6>{errorMessage}</h6>
          </div>
        )}

        {isLoading && (
          <div>
            <Loader variation="linear" filledColor="brown" />
          </div>
        )}
      </div>
    </div>
  );
};

export default ForgotPassword;
