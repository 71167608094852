import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";
import { Provider } from "react-redux";
import store from "./configure_store";
import { Authenticator } from "@aws-amplify/ui-react";
import amplifyconfig from "./amplifyconfiguration.json";
import { Amplify } from "aws-amplify";

Amplify.configure(amplifyconfig);

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Provider store={store()}>
    <Authenticator.Provider>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </Authenticator.Provider>
  </Provider>
);
