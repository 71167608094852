import { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper/modules";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/autoplay";
import Photo1 from "./../../assets/images/home-slider/frontview.JPG";
import Photo2 from "./../../assets/images/home-slider/living.JPG";
import Photo3 from "./../../assets/images/home-slider/tv.JPG";
import Photo4 from "./../../assets/images/home-slider/courtyard.JPG";
import Photo5 from "./../../assets/images/home-slider/dining.JPG";
import Photo6 from "./../../assets/images/home-slider/balcony.JPG";
import Photo7 from "./../../assets/images/home-slider/mirror.jpg";

const HomePhotoSlider = () => {
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setViewportHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const slideStyles = {
    height: `${viewportHeight}px`,
  };

  const imageStyles = {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  };

  return (
    <div id="home">
      <Swiper
        modules={[Pagination, Navigation, Scrollbar, A11y, Autoplay]}
        centeredSlides={true}
        spaceBetween={100}
        slidesPerView={1}
        speed={1200}
        navigation
        pagination={{ clickable: true }}
        autoplay={{ delay: 4000, disableOnInteraction: false }}
      >
        <SwiperSlide style={slideStyles}>
          <img src={Photo1} style={imageStyles} alt="slide-1" />
        </SwiperSlide>
        <SwiperSlide style={slideStyles}>
          <img src={Photo2} style={imageStyles} alt="slide-2" />
        </SwiperSlide>
        <SwiperSlide style={slideStyles}>
          <img src={Photo3} style={imageStyles} alt="slide-3" />
        </SwiperSlide>
        <SwiperSlide style={slideStyles}>
          <img src={Photo4} style={imageStyles} alt="slide-4" />
        </SwiperSlide>
        <SwiperSlide style={slideStyles}>
          <img src={Photo5} style={imageStyles} alt="slide-5" />
        </SwiperSlide>
        <SwiperSlide style={slideStyles}>
          <img src={Photo6} style={imageStyles} alt="slide-6" />
        </SwiperSlide>
        <SwiperSlide style={slideStyles}>
          <img src={Photo7} style={imageStyles} alt="slide-7" />
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default HomePhotoSlider;
