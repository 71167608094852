import React from "react";
import {
  galleryContent,
  galleryContentInTamil,
  galleryHeader,
  galleryHeaderInTamil,
} from "../../content";
import { useSelector } from "react-redux";
import PhotoSlider from "../commons/PhotoSlider";
import Photo1 from "./../../assets/images/home-slider/frontview.JPG";
import Photo2 from "./../../assets/images/home-slider/living.JPG";
import Photo3 from "./../../assets/images/home-slider/tv.JPG";
import Photo4 from "./../../assets/images/home-slider/courtyard.JPG";
import Photo5 from "./../../assets/images/home-slider/dining.JPG";
import Photo6 from "./../../assets/images/home-slider/bedroom.JPG";
import Photo7 from "./../../assets/images/home-slider/balcony.JPG";

const Gallery = () => {
  const appCommonSelector = (state) => state.appCommon;
  const appCommonData = useSelector(appCommonSelector);

  const images = [Photo1, Photo2, Photo3, Photo4, Photo5, Photo6, Photo7];
  return (
    <>
      <div className={appCommonData.appLanguage} id="gallery">
        <h1>
          {appCommonData.appLanguage === "English"
            ? galleryHeader
            : galleryHeaderInTamil}
        </h1>
        <p>
          {appCommonData.appLanguage === "English"
            ? galleryContent
            : galleryContentInTamil}
        </p>
        <PhotoSlider images={images} />
      </div>
    </>
  );
};

export default Gallery;
