import React from "react";
import { useSelector } from "react-redux";
import KVLogo from "./../../assets/images/KVLogo.png";
import fbIcon from "./../../assets/images/social/fbicon.png";
import instaIcon from "./../../assets/images/social/insta-icon.png";
import twitterIcom from "./../../assets/images/social/twitter-icon.png";
import linkedinIcon from "./../../assets/images/social/linkedin-icon.png";

const ContactUs = () => {
  const appCommonSelector = (state) => state.appCommon;
  const appCommonData = useSelector(appCommonSelector);

  const imageStyles = {
    margin: 10,
    border: "2px solid white",
    borderRadius: "7px",
    borderColor: "white",
  };
  return (
    <>
      <div id="contact">
        <div className="cu-container">
          <div className="box">
            <img src={KVLogo} width="25%" alt="logo" />
            <h4 style={{ fontFamily: "Pacifico", margin: "2%" }}>
              {appCommonData.appLanguage === "English"
                ? "Kalyana Veedu"
                : "கல்யாண வீடு"}
            </h4>
            <h6>
              {appCommonData.appLanguage === "English"
                ? "THE PEACHTREE GROUP"
                : "தீ பீச்ட்ரீ குழுமம்"}
            </h6>
            <a
              href="https://www.facebook.com/profile.php?id=61554440535379"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={fbIcon}
                style={imageStyles}
                width="30px"
                height="30px"
                alt="fbicon"
              />
            </a>
            <a
              href="https://www.instagram.com/kalyaanaveedu/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={instaIcon}
                style={imageStyles}
                width="30px"
                height="30px"
                alt="fbicon"
              />
            </a>
            <a
              href="https://twitter.com/kalyaana_veedu"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={twitterIcom}
                style={imageStyles}
                width="30px"
                height="30px"
                alt="fbicon"
              />
            </a>
            <a
              href="https://www.linkedin.com/company/kalyaana-veedu/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={linkedinIcon}
                style={imageStyles}
                width="30px"
                height="30px"
                alt="fbicon"
              />
            </a>
          </div>
          <hr />
          <div className="box">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1162.3807915457849!2d77.7743386410276!3d10.180030268599568!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b074f001d8a448b%3A0x994061d7f864afe4!2sKalyaana%20Veedu!5e1!3m2!1sen!2sin!4v1710043706257!5m2!1sen!2sin"
              width="350"
              height="250"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
          <hr />
          <div className="box">
            <h5 style={{ fontFamily: "Pacifico", margin: "2%" }}>
              {appCommonData.appLanguage === "English"
                ? "Kalyana Veedu"
                : "கல்யாண வீடு"}
            </h5>
            <h6>
              {appCommonData.appLanguage === "English"
                ? "Kodaikanal Road, Dindigul Bypass,"
                : "கொடைக்காணல் ரோடு, திண்டுக்கல் பைபாஸ், "}
            </h6>
            <h6>
              {appCommonData.appLanguage === "English"
                ? "Sevugampatti - 624211"
                : "சேவுகம்பட்டி - 624211"}
            </h6>
            <hr />
            <h5>
              {appCommonData.appLanguage === "English"
                ? "For Bookings & Enquiries"
                : "தொடர்புக்கு"}
            </h5>
            <h6>support@kalyaanaveedu.com</h6>
            <h6>+91 73057 15039 </h6>
            <hr />
            <p>
              {appCommonData.appLanguage === "English"
                ? "Copyright © 2024. All rights reserved."
                : "பதிப்புரிமை © 2024. அனைத்து உரிமைகளும் பாதுகாக்கப்பட்டவை."}
              <br />
              {appCommonData.appLanguage === "English"
                ? "This website is made with ❤️ by Abdul Basith"
                : "இந்த இணையதளத்தை உருவாக்கியவர் அப்துல் பாஸித்"}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
