import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Loader } from "@aws-amplify/ui-react";
import {
  fetchAuthSession,
  fetchUserAttributes,
  updateUserAttributes,
} from "aws-amplify/auth";
import "@aws-amplify/ui-react/styles.css";
import "./css/MyAccount.css";
import LogoSection from "../commons/LogoSection";

const MyAccount = () => {
  const [isLoading, setLoading] = useState(false);
  const appCommonSelector = (state) => state.appCommon;
  const appCommonData = useSelector(appCommonSelector);

  //user Attributes
  const [name, setName] = useState("");
  const [userName, setUserName] = useState("");
  const [birthdate, setBirthDate] = useState("");

  const [accountErrorMessage, setAccountErrorMessage] = useState("");

  useEffect(() => {
    const loadUserAttributes = async () => {
      try {
        setLoading(true);
        const userAttributes = await fetchUserAttributes();
        setName(userAttributes.name);
        setUserName(
          userAttributes.email
            ? userAttributes.email
            : userAttributes.phone_number
        );
        setBirthDate(userAttributes.birthdate);
      } catch (e) {
        setAccountErrorMessage(
          "Error in fetching account details : " + e.message
        );
      } finally {
        setLoading(false);
      }
    };
    loadUserAttributes();
  }, []);

  const validInputs = () => {
    if (!name) {
      setAccountErrorMessage("Name cannot be empty.");
      return false;
    }
    if (!birthdate) {
      setAccountErrorMessage("Birth date cannot be empty.");
      return false;
    }
    return true;
  };

  const handleUpdateProfile = async () => {
    try {
      setLoading(true);
      if (validInputs()) {
        await updateUserAttributes({
          userAttributes: {
            name: name,
            birthdate: birthdate,
          },
        });
        setAccountErrorMessage("User Attributes updated successfully!");
      }
    } catch (e) {
      setAccountErrorMessage(
        "Error in updating account details : " + e.message
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="my-account-top-container">
      <div className="my-account-main-container">
        <LogoSection />
        <h6
          style={{
            fontFamily: "Merriweather",
            margin: "2%",
            textAlign: "center",
          }}
        >
          {appCommonData.appLanguage === "English" ? "My Account" : "சுயவிவரம்"}
        </h6>
        <label>
          {appCommonData.appLanguage === "English" ? "Username" : "பயனர்"}
          <input type="text" value={userName} disabled />
        </label>
        <label>
          {appCommonData.appLanguage === "English" ? "Name" : "பெயர்"}
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </label>
        <label>
          {appCommonData.appLanguage === "English"
            ? "Birth date"
            : "பிறந்த தேதி"}
          <input
            type="date"
            value={birthdate}
            onChange={(e) => setBirthDate(e.target.value)}
          />
        </label>
        <button type="button" onClick={handleUpdateProfile}>
          {appCommonData.appLanguage === "English"
            ? "Update Profile"
            : "சுயவிவரத்தைப் புதுப்பிக்கவும்"}
        </button>
        {isLoading && (
          <div className="my-account-loader-container">
            <Loader variation="linear" filledColor="brown" />
          </div>
        )}
        {accountErrorMessage && (
          <div className="error-messages-container">
            <h6>{accountErrorMessage}</h6>
          </div>
        )}
      </div>
    </div>
  );
};

export default MyAccount;
