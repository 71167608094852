import React from "react";
import KVLogo from "./../../assets/images/KVLogoOnly.jpg";
import { useSelector } from "react-redux";

const LogoSection = () => {
  const appCommonSelector = (state) => state.appCommon;
  const appCommonData = useSelector(appCommonSelector);
  return (
    <>
      <div className="logo-img-container">
        <img src={KVLogo} alt="KVLogo" />
      </div>
      <h4
        style={{
          fontFamily: "Pacifico",
          margin: "2%",
          color: "#470303",
          textAlign: "center",
        }}
      >
        {appCommonData.appLanguage === "English"
          ? "Kalyana Veedu"
          : "கல்யாண வீடு"}
      </h4>
    </>
  );
};

export default LogoSection;
