import React, { useState } from "react";
import LogoSection from "../commons/LogoSection";
import "./css/ChangePassword.css";
import { useSelector } from "react-redux";
import { Loader } from "@aws-amplify/ui-react";
import { signOut, updatePassword } from "aws-amplify/auth";
import { useNavigate } from "react-router-dom";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "react-bootstrap";

const ChangePassword = () => {
  const appCommonSelector = (state) => state.appCommon;
  const appCommonData = useSelector(appCommonSelector);
  const navigate = useNavigate();

  const [isLoading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showModalAlert, setShowModalAlert] = useState(false);

  //change password hooks
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleShowPasswordChange = () => {
    setShowPassword(!showPassword);
  };

  const validChangePasswordInputs = () => {
    if (!oldPassword || !newPassword || !confirmPassword) {
      setErrorMessage("Passwords cannot be empty.");
      return false;
    }
    if (newPassword !== confirmPassword) {
      setErrorMessage("Passwords does not match.");
      return false;
    }
    if (newPassword.length < 8 || newPassword.length > 16) {
      setErrorMessage("Password length should be between 8 and 16 characters.");
      return false;
    }
    return true;
  };

  const handleChangePassword = async () => {
    try {
      setShowModalAlert(false);
      setLoading(true);
      if (validChangePasswordInputs()) {
        await updatePassword({ oldPassword, newPassword });
        setErrorMessage(
          "Password update successful. Redirecting to login in 3 seconds.."
        );
        delay(3000);
      }
    } catch (error) {
      setErrorMessage("error while changing password : " + error.message);
    } finally {
      setLoading(false);
      await signOut();
      navigate("/login");
    }
  };

  const delay = (ms) => new Promise((res) => setTimeout(res, ms));

  return (
    <div className="change-password-top-container">
      <div className="change-password-main-container">
        <LogoSection />
        <h6
          style={{
            fontFamily: "Merriweather",
            margin: "2%",
            textAlign: "center",
          }}
        >
          {appCommonData.appLanguage === "English"
            ? "Change Password"
            : "கடவுச்சொல் மாற்றம்"}
        </h6>
        <label>
          {appCommonData.appLanguage === "English"
            ? "Old password"
            : "பழைய கடவுச்சொல்"}
          <input
            type={showPassword ? "text" : "password"}
            value={oldPassword}
            onChange={(e) => setOldPassword(e.target.value)}
          />
        </label>
        <hr />
        <label>
          {appCommonData.appLanguage === "English"
            ? "New password"
            : "புதிய கடவுச்சொல்"}
          <input
            type={showPassword ? "text" : "password"}
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
        </label>
        <label>
          {appCommonData.appLanguage === "English"
            ? "Confirm new password"
            : "புதிய கடவுச்சொல்லை உறுதிப்படுத்தவும்"}
          <input
            type={showPassword ? "text" : "password"}
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
        </label>
        <label>
          <input
            type="checkbox"
            value={showPassword}
            checked={showPassword}
            onChange={handleShowPasswordChange}
          />
          {appCommonData.appLanguage === "English"
            ? " Show password"
            : " கடவுச்சொல்லை காட்டவும்"}
        </label>
        <button type="button" onClick={() => setShowModalAlert(true)}>
          {appCommonData.appLanguage === "English"
            ? "Change Password"
            : "கடவுச்சொல்லை மாற்று"}
        </button>
        {isLoading && (
          <div className="change-password-loader-container">
            <Loader variation="linear" filledColor="brown" />
          </div>
        )}
        {errorMessage && (
          <div className="error-messages-container">
            <h6>{errorMessage}</h6>
          </div>
        )}
        {showModalAlert && (
          <Modal show={showModalAlert}>
            <ModalHeader>
              {appCommonData.appLanguage === "English" ? "Info" : "தகவல்"}
            </ModalHeader>
            <ModalBody>
              {appCommonData.appLanguage === "English"
                ? "Your password will be updated on clicking update password update and your session will be logged out. You need to login again with the new password."
                : "கடவுச்சொல்லை புதுப்பி-ஐ  கிளிக் செய்வதன் மூலம் உங்கள் கடவுச்சொல் புதுப்பிக்கப்படும் மற்றும் உங்கள் அமர்வு வெளியேறும். புதிய கடவுச்சொல் மூலம் மீண்டும் உள்நுழைய வேண்டும்."}
            </ModalBody>
            <ModalFooter>
              <button
                type="button"
                className="secondary-button"
                onClick={() => setShowModalAlert(false)}
              >
                {appCommonData.appLanguage === "English"
                  ? "Cancel"
                  : "ரத்து செய்"}
              </button>
              <button
                type="button"
                className="primary-button"
                onClick={handleChangePassword}
              >
                {appCommonData.appLanguage === "English"
                  ? "Update Password"
                  : "கடவுச்சொல்லை புதுப்பி"}
              </button>
            </ModalFooter>
          </Modal>
        )}
      </div>
    </div>
  );
};

export default ChangePassword;
