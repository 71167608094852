import React from "react";
import OurStory from "./OurStory";
import HomePhotoSlider from "../commons/HomePhotoSlider";
import OurRooms from "./OurRooms";
import Experience from "./Experience";
import Dining from "./Dining";
import Gallery from "./Gallery";
import ContactUs from "./ContactUs";

const Landing = () => {
  return (
    <>
      <HomePhotoSlider />
      <OurStory />
      <OurRooms />
      <Experience />
      <Dining />
      <Gallery />
      <ContactUs />
    </>
  );
};

export default Landing;
