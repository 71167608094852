import React from "react";
import {
  ourRoomsContent,
  ourRoomsContentInTamil,
  ourRoomsHeader,
  ourRoomsHeaderInTamil,
} from "../../content";
import { useSelector } from "react-redux";
import RoomGrid from "../rooms/RoomGrid";

const OurRooms = () => {
  const appCommonSelector = (state) => state.appCommon;
  const appCommonData = useSelector(appCommonSelector);

  return (
    <div>
      <div className={appCommonData.appLanguage} id="rooms">
        <h1>
          {appCommonData.appLanguage === "English"
            ? ourRoomsHeader
            : ourRoomsHeaderInTamil}
        </h1>
        <p>
          {appCommonData.appLanguage === "English"
            ? ourRoomsContent
            : ourRoomsContentInTamil}
        </p>
        <div
          className="grid-container"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <RoomGrid />
        </div>
      </div>
    </div>
  );
};

export default OurRooms;
