import { AppCommonActionTypes, AppCommonState } from "./types";

const initialState: AppCommonState = {
  appLanguage: "English",
  isNavbarExpanded: false,
};

export const AppCommonReducer = (
  state = initialState,
  action: AppCommonActionTypes
) => {
  switch ((action as AppCommonActionTypes).type) {
    case "@@APP_COMMON/UPDATE_APP_LANGUAGE":
      return { ...state, appLanguage: action.payload };
    case "@@APP_COMMON/HANDLE_NAV_ITEM_SELECT":
      return { ...state, isNavbarExpanded: action.payload };
    default:
      return state;
  }
};
