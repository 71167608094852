import { UserActionTypes, UserState } from "./types";

const initialState: UserState = {
  userRole: "unauthenticated",
};

export const UserReducer = (state = initialState, action: UserActionTypes) => {
  switch ((action as UserActionTypes).type) {
    case "@@APP_COMMON/UPDATE_USER_ROLE":
      return { ...state, userRole: action.payload };
    default:
      return state;
  }
};
