import React from "react";
import { Link } from "react-scroll";
import {
  Badge,
  Button,
  ButtonGroup,
  Container,
  Nav,
  Navbar,
  ToggleButton,
} from "react-bootstrap";
import "./css/AppNavbar.css";
import { useDispatch, useSelector } from "react-redux";
import {
  handleNavItemSelect,
  updateAppLanguage,
} from "../../reducers/app-common/actions";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { Link as RouterLink } from "react-router-dom";
import NavBrandLogo from "./../../assets/images/KVNavBrand.png";
import MyProfileDropDown from "../auth/MyProfileDropDown";
import AdminControlsDropDown from "../auth/AdminControlsDropDown";

const AppNavbar = () => {
  const appCommonSelector = (state) => state.appCommon;
  const appCommonData = useSelector(appCommonSelector);
  const userSelector = (state) => state.user;
  const userData = useSelector(userSelector);
  const dispatch = useDispatch();

  const { authStatus } = useAuthenticator((context) => [context.authStatus]);

  return (
    <>
      <Navbar
        expand="lg"
        expanded={appCommonData.isNavbarExpanded}
        variant="dark"
        fixed="top"
        className="navbar"
      >
        <Container id="nav-container">
          <Navbar.Brand
            as={RouterLink}
            to="/"
            onClick={(e) => {
              dispatch(handleNavItemSelect(!appCommonData.isNavbarExpanded));
            }}
          >
            <img id="navbar-brand-logo" src={NavBrandLogo} alt="logo" />
            {appCommonData.appLanguage === "English"
              ? "Kalyaana Veedu"
              : "கல்யாண வீடு"}
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls="responsive-navbar-nav"
            onClick={(e) => {
              dispatch(handleNavItemSelect(!appCommonData.isNavbarExpanded));
            }}
          />
          <Navbar.Collapse
            id="responsive-navbar-nav"
            className="justify-content-end"
          >
            {window.location.pathname === "/" && (
              <Nav id="nav-items">
                <Link
                  spy={true}
                  smooth={true}
                  to="home"
                  offset={-90}
                  duration={500}
                  className="custom-navbar-link"
                  onClick={(e) => {
                    dispatch(
                      handleNavItemSelect(!appCommonData.isNavbarExpanded)
                    );
                  }}
                >
                  {appCommonData.appLanguage === "English"
                    ? "Home"
                    : "தொடக்கம்"}
                </Link>
                <Link
                  spy={true}
                  smooth={true}
                  to="story"
                  offset={-80}
                  duration={500}
                  className="custom-navbar-link"
                  onClick={(e) => {
                    dispatch(
                      handleNavItemSelect(!appCommonData.isNavbarExpanded)
                    );
                  }}
                >
                  {appCommonData.appLanguage === "English"
                    ? "Our Story"
                    : "நாங்கள்"}
                </Link>
                <Link
                  spy={true}
                  smooth={true}
                  to="rooms"
                  offset={-80}
                  duration={500}
                  className="custom-navbar-link"
                  onClick={(e) => {
                    dispatch(
                      handleNavItemSelect(!appCommonData.isNavbarExpanded)
                    );
                  }}
                >
                  {appCommonData.appLanguage === "English" ? "Rooms" : "அறைகள்"}
                </Link>
                <Link
                  spy={true}
                  smooth={true}
                  to="experience"
                  offset={-80}
                  duration={500}
                  className="custom-navbar-link"
                  onClick={(e) => {
                    dispatch(
                      handleNavItemSelect(!appCommonData.isNavbarExpanded)
                    );
                  }}
                >
                  {appCommonData.appLanguage === "English"
                    ? "Experience"
                    : "அனுபவம்"}
                </Link>
                <Link
                  spy={true}
                  smooth={true}
                  to="dining"
                  offset={-80}
                  duration={500}
                  className="custom-navbar-link"
                  onClick={(e) => {
                    dispatch(
                      handleNavItemSelect(!appCommonData.isNavbarExpanded)
                    );
                  }}
                >
                  {appCommonData.appLanguage === "English"
                    ? "Dining"
                    : "உணவகம்"}
                </Link>
                <Link
                  spy={true}
                  smooth={true}
                  to="gallery"
                  offset={-80}
                  duration={500}
                  className="custom-navbar-link"
                  onClick={(e) => {
                    dispatch(
                      handleNavItemSelect(!appCommonData.isNavbarExpanded)
                    );
                  }}
                >
                  {appCommonData.appLanguage === "English"
                    ? "Gallery"
                    : "காட்சிகள்"}
                </Link>
                <Link
                  spy={true}
                  smooth={true}
                  to="contact"
                  offset={50}
                  duration={500}
                  className="custom-navbar-link"
                  onClick={(e) => {
                    dispatch(
                      handleNavItemSelect(!appCommonData.isNavbarExpanded)
                    );
                  }}
                >
                  {appCommonData.appLanguage === "English"
                    ? "Contact us"
                    : "தொடர்புக்கு"}
                </Link>
              </Nav>
            )}
          </Navbar.Collapse>
          <Navbar.Collapse
            id="responsive-navbar-nav"
            className="justify-content-end"
          >
            <Nav id="nav-items">
              {/*authStatus !== "authenticated" && (
                <Button
                  className="btn-white"
                  id="custom-button"
                  as={RouterLink}
                  to="/login"
                  onClick={handleNavItemSelect}
                >
                  {appCommonData.appLanguage === "English"
                    ? "Login / Sign Up"
                    : "உள்நுழை / பதிவு செய்"}
                </Button>
              ) */}
              {authStatus === "authenticated" && (
                <>
                  {userData.userRole === "Guest" && (
                    <>
                      <Button
                        className="btn"
                        id="custom-button"
                        as={RouterLink}
                        to="/book"
                        onClick={(e) => {
                          dispatch(
                            handleNavItemSelect(!appCommonData.isNavbarExpanded)
                          );
                        }}
                      >
                        {appCommonData.appLanguage === "English"
                          ? "Book a stay"
                          : "முன்பதிவு"}
                      </Button>
                      <MyProfileDropDown />
                    </>
                  )}
                  {userData.userRole === "Admin" && <AdminControlsDropDown />}
                </>
              )}
              <Badge
                bg=""
                style={{
                  backgroundColor: "#470303 !important",
                  color: "white",
                  fontFamily: "Merriweather",
                  fontSize: "medium",
                  padding: "10px",
                }}
              >
                {appCommonData.appLanguage === "English"
                  ? "For Booking : +91 73057 15039"
                  : "முன்பதிவு தொடர்புக்கு : +91 73057 15039"}
              </Badge>

              <ButtonGroup id="langugage-toggle-button">
                <ToggleButton
                  key={1}
                  id={"english"}
                  type="radio"
                  variant="outline-light"
                  name="radio"
                  value={appCommonData.appLanguage}
                  checked={appCommonData.appLanguage === "English"}
                  onChange={(e) => {
                    dispatch(updateAppLanguage("English"));
                  }}
                  onClick={(e) => {
                    dispatch(handleNavItemSelect(false));
                  }}
                >
                  En
                </ToggleButton>
                <ToggleButton
                  key={2}
                  id={"tamil"}
                  type="radio"
                  variant="outline-light"
                  name="radio"
                  value={appCommonData.appLanguage}
                  checked={appCommonData.appLanguage === "Tamil"}
                  onChange={(e) => {
                    dispatch(updateAppLanguage("Tamil"));
                  }}
                  onClick={(e) => {
                    dispatch(
                      handleNavItemSelect(!appCommonData.isNavbarExpanded)
                    );
                  }}
                >
                  த
                </ToggleButton>
              </ButtonGroup>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default AppNavbar;
