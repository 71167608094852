export const welcomeHeader = "Welcome to Kalyana Veedu!!!";

export const welcomeHeaderInTamil =
  "கல்யாண வீட்டுக்கு உங்களை அன்புடன் வரவேற்கிறோம்!!!";

export const welcomeSubHeader = "Your joy is our passion";

export const welcomeSubHeaderInTamil = "உங்கள் மகிழ்ச்சியே எங்கள் ஆர்வம்!";

export const welcomeContent =
  "Step into a world of enchantment at Kalyana Veedu, where dreams come to life and celebrations become timeless. Nestled in the heart of Batlagundu, on the foothills of Kodaikanal, our home is more than just a venue; it's a canvas for crafting unforgettable moments. At Kalyana Veedu, we understand that your gathering is a symphony of emotions, and we're here to orchestrate every note to perfection. Welcome to a place where your stay becomes a memory.";

export const welcomeContentInTamil =
  "மலைகளின் ராணி, கொடைக்கானலின் அடிவாரதில் உள்ள வத்தலகுண்டு மைய பகுதியில் அமைந்திருக்கும் கல்யாண வீடு, ஒரு தங்குமிடம் மட்டுமல்ல, மறக்க முடியாத தருணங்களை வடிவமைக்கும் ஒரு எழில் கூடம். கல்யாண வீட்டில் நீங்கள் கழிக்கும் பொழுதுகள், என்றென்றும் மாறாத நினைவுகளாய் உங்கள் மனதோடு கதை பேசும். வீட்டு சூழளுடன் ஆடம்பர வசதியின் கலவையை அனுபவிக்க கல்யாண வீட்டுக்கு உங்களை அன்புடன் வரவேற்கிறோம்.";

export const whoWeAreHeader = "Who we are ?";

export const whoWeAreHeaderInTamil = "நாம் யார்?";

export const whoWeAreContent =
  '"Kalyana Veedu" is a Tamil word that means the house of wedding. As the name suggests, Kalyana Veedu is the destination for your celebrations and precious moments of life, such as pre- or post-wedding events, baby showers, get-togethers, birthdays, etc. Whether it\'s a celebration, a joyous get-together, a family reunion, or an exquisite stay, we aim to provide a seamless blend of luxury and comfort with a homely ambiance.';

export const whoWeAreContentInTamilOne =
  "பாரம்பரியம் உயிர்ப்பிக்கும் முயற்சியில் நாங்கள்.....";

export const whoWeAreContentInTamilTwo =
  "கல்யாண வீடு ஒரு உறவின் அடையாளம். பெயர்க்கேற்றார் போல, மகிழ்ச்சியின் கொண்டாட்டம். குடும்ப நிகழ்வுகள், குடுப்ப ஒன்று கூடல், விடுமுறை, சுற்றுலா போன்ற அனைத்திற்கும் ஒரு நேர்த்தியான தங்குமிடம்.";

export const ourMissionHeader = "Our Mission";

export const ourMissionHeaderInTamil = "எங்கள் நோக்கம்";

export const ourMissionContent =
  "To revive the traditional way of celebrating life's most significant moments in a homely atmosphere.";

export const ourMissionContentInTamil =
  "வாழ்க்கையின் மிக முக்கிய தருணங்களை உறவுகளுடன் கொண்டாடும் பாரம்பரிய பழக்கத்தை புதுப்பித்தல்.";

export const ourRoomsHeader = "Our Chambers";

export const ourRoomsHeaderInTamil = "எங்கள் அறைகள்";

export const ourRoomsContent =
  "At Kalyana Veedu, we understand that the key to a memorable stay lies in the details, and our meticulously designed rooms are a testament to that philosophy.";

export const ourRoomsContentInTamil = "";

export const experienceHeader =
  "Experience The Celebration at Kalyana Veedu!!!";

export const experienceHeaderInTamil =
  "கல்யாண வீட்டின் கொண்டாட்டத்தை அனுபவியுங்கள்!!!";

export const diningHeader = "Dining & Cooks";

export const diningHeaderInTamil = "உணவகம் & சமையல்காரர்கள்";

export const diningContent =
  "Step into our dining hall with an open layout, making it the ideal setting for festive gatherings, celebrations, and shared moments.";

export const diningContentInTamil = "";

export const spaciousDiningHeader = "Spacious Dining!!";

export const spaciousDiningHeaderInTamil = "உணவகம்!!";

export const spaciousDiningContent =
  "Step into our dining hall, an open layout making it the ideal setting for festive gatherings, celebrations, and shared moments.";

export const spaciousDiningContentInTamil =
  "எங்கள் சாப்பாட்டு அறைக்குள் நுழையுங்கள், ஒரு விசாலமான மற்றும் நேர்த்தியான விரிவாக்கம், அங்கு பரந்த ஜன்னல்கள் வழியாக இயற்கை ஒளி நடனமாடுகிறது, மேலும் கவனமாக அலங்கரிக்கப்பட்ட அலங்காரமானது அழைக்கும் சூழ்நிலையை உருவாக்குகிறது. திறந்த தளவமைப்பு சமூக உணர்வை ஊக்குவிக்கிறது, இது பண்டிகைக் கூட்டங்கள், கொண்டாட்டங்கள் மற்றும் ஆடம்பரமான உணவுகளுடன் பகிர்ந்து கொள்ளும் தருணங்களுக்கு ஏற்ற அமைப்பாக அமைகிறது.";

export const bookACookHeader = "Book a Cook!!";

export const bookACookHeaderInTamil = "சமையல்காரர் முன்பதிவு!!";

export const bookACookContent =
  "At Kalyana Veedu, we believe that dining is not just a meal; it's a journey of flavors, an exploration of culinary artistry, and a celebration of the senses. Welcome to a gastronomic haven where every dish tells a story and every bite is a symphony of taste. Book your cook today!!";

export const bookACookContentInTamil =
  "கல்யாண வீட்டில், சாப்பாடு என்பது வெறும் சாப்பாடு மட்டுமல்ல; இது சுவைகளின் பயணம், சமையல் கலையின் ஆய்வு மற்றும் உணர்வுகளின் கொண்டாட்டம். ஒரு காஸ்ட்ரோனமிக் புகலிடத்திற்கு வரவேற்கிறோம், அங்கு ஒவ்வொரு உணவும் ஒரு கதையைச் சொல்கிறது மற்றும் ஒவ்வொரு கடியும் சுவையின் சிம்பொனி. இன்றே உங்கள் சமையல்காரரை முன்பதிவு செய்யுங்கள்!!";

export const galleryHeader = "Hear from our Customers!";

export const galleryHeaderInTamil = "வாடிக்கையாளர் குரல்";

export const galleryContent =
  "At Kalyana Veedu, the heartbeat of our success is the voice of our valued customers. Here's what some of our cherished guests have to say:";

export const galleryContentInTamil =
  "எங்கள் நேசத்திற்குரிய விருந்தினர் சிலர் கூறுவது இங்கே.";
