/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createAddonServicePrice = /* GraphQL */ `
  mutation CreateAddonServicePrice(
    $input: CreateAddonServicePriceInput!
    $condition: ModelAddonServicePriceConditionInput
  ) {
    createAddonServicePrice(input: $input, condition: $condition) {
      id
      service_name
      price
      created_by
      last_updated_by
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAddonServicePrice = /* GraphQL */ `
  mutation UpdateAddonServicePrice(
    $input: UpdateAddonServicePriceInput!
    $condition: ModelAddonServicePriceConditionInput
  ) {
    updateAddonServicePrice(input: $input, condition: $condition) {
      id
      service_name
      price
      created_by
      last_updated_by
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAddonServicePrice = /* GraphQL */ `
  mutation DeleteAddonServicePrice(
    $input: DeleteAddonServicePriceInput!
    $condition: ModelAddonServicePriceConditionInput
  ) {
    deleteAddonServicePrice(input: $input, condition: $condition) {
      id
      service_name
      price
      created_by
      last_updated_by
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCDRate = /* GraphQL */ `
  mutation CreateCDRate(
    $input: CreateCDRateInput!
    $condition: ModelCDRateConditionInput
  ) {
    createCDRate(input: $input, condition: $condition) {
      id
      slab_start_price
      slab_end_price
      cd_rate
      is_active
      created_by
      last_updated_by
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCDRate = /* GraphQL */ `
  mutation UpdateCDRate(
    $input: UpdateCDRateInput!
    $condition: ModelCDRateConditionInput
  ) {
    updateCDRate(input: $input, condition: $condition) {
      id
      slab_start_price
      slab_end_price
      cd_rate
      is_active
      created_by
      last_updated_by
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCDRate = /* GraphQL */ `
  mutation DeleteCDRate(
    $input: DeleteCDRateInput!
    $condition: ModelCDRateConditionInput
  ) {
    deleteCDRate(input: $input, condition: $condition) {
      id
      slab_start_price
      slab_end_price
      cd_rate
      is_active
      created_by
      last_updated_by
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createTaxRate = /* GraphQL */ `
  mutation CreateTaxRate(
    $input: CreateTaxRateInput!
    $condition: ModelTaxRateConditionInput
  ) {
    createTaxRate(input: $input, condition: $condition) {
      id
      slab_start_price
      slab_end_price
      tax_rate
      is_active
      created_by
      last_updated_by
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateTaxRate = /* GraphQL */ `
  mutation UpdateTaxRate(
    $input: UpdateTaxRateInput!
    $condition: ModelTaxRateConditionInput
  ) {
    updateTaxRate(input: $input, condition: $condition) {
      id
      slab_start_price
      slab_end_price
      tax_rate
      is_active
      created_by
      last_updated_by
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteTaxRate = /* GraphQL */ `
  mutation DeleteTaxRate(
    $input: DeleteTaxRateInput!
    $condition: ModelTaxRateConditionInput
  ) {
    deleteTaxRate(input: $input, condition: $condition) {
      id
      slab_start_price
      slab_end_price
      tax_rate
      is_active
      created_by
      last_updated_by
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createPromoCode = /* GraphQL */ `
  mutation CreatePromoCode(
    $input: CreatePromoCodeInput!
    $condition: ModelPromoCodeConditionInput
  ) {
    createPromoCode(input: $input, condition: $condition) {
      id
      start_date
      end_date
      discount_percentage
      created_by
      last_updated_by
      promo_code
      is_active
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updatePromoCode = /* GraphQL */ `
  mutation UpdatePromoCode(
    $input: UpdatePromoCodeInput!
    $condition: ModelPromoCodeConditionInput
  ) {
    updatePromoCode(input: $input, condition: $condition) {
      id
      start_date
      end_date
      discount_percentage
      created_by
      last_updated_by
      promo_code
      is_active
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deletePromoCode = /* GraphQL */ `
  mutation DeletePromoCode(
    $input: DeletePromoCodeInput!
    $condition: ModelPromoCodeConditionInput
  ) {
    deletePromoCode(input: $input, condition: $condition) {
      id
      start_date
      end_date
      discount_percentage
      created_by
      last_updated_by
      promo_code
      is_active
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createPriceType = /* GraphQL */ `
  mutation CreatePriceType(
    $input: CreatePriceTypeInput!
    $condition: ModelPriceTypeConditionInput
  ) {
    createPriceType(input: $input, condition: $condition) {
      id
      price_type
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updatePriceType = /* GraphQL */ `
  mutation UpdatePriceType(
    $input: UpdatePriceTypeInput!
    $condition: ModelPriceTypeConditionInput
  ) {
    updatePriceType(input: $input, condition: $condition) {
      id
      price_type
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deletePriceType = /* GraphQL */ `
  mutation DeletePriceType(
    $input: DeletePriceTypeInput!
    $condition: ModelPriceTypeConditionInput
  ) {
    deletePriceType(input: $input, condition: $condition) {
      id
      price_type
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createPriceDetail = /* GraphQL */ `
  mutation CreatePriceDetail(
    $input: CreatePriceDetailInput!
    $condition: ModelPriceDetailConditionInput
  ) {
    createPriceDetail(input: $input, condition: $condition) {
      id
      start_date
      end_date
      base_price
      is_active
      created_by
      last_updated_by
      PriceType {
        id
        price_type
        createdAt
        updatedAt
        __typename
      }
      roomID
      createdAt
      updatedAt
      priceDetailPriceTypeId
      __typename
    }
  }
`;
export const updatePriceDetail = /* GraphQL */ `
  mutation UpdatePriceDetail(
    $input: UpdatePriceDetailInput!
    $condition: ModelPriceDetailConditionInput
  ) {
    updatePriceDetail(input: $input, condition: $condition) {
      id
      start_date
      end_date
      base_price
      is_active
      created_by
      last_updated_by
      PriceType {
        id
        price_type
        createdAt
        updatedAt
        __typename
      }
      roomID
      createdAt
      updatedAt
      priceDetailPriceTypeId
      __typename
    }
  }
`;
export const deletePriceDetail = /* GraphQL */ `
  mutation DeletePriceDetail(
    $input: DeletePriceDetailInput!
    $condition: ModelPriceDetailConditionInput
  ) {
    deletePriceDetail(input: $input, condition: $condition) {
      id
      start_date
      end_date
      base_price
      is_active
      created_by
      last_updated_by
      PriceType {
        id
        price_type
        createdAt
        updatedAt
        __typename
      }
      roomID
      createdAt
      updatedAt
      priceDetailPriceTypeId
      __typename
    }
  }
`;
export const createPayment = /* GraphQL */ `
  mutation CreatePayment(
    $input: CreatePaymentInput!
    $condition: ModelPaymentConditionInput
  ) {
    createPayment(input: $input, condition: $condition) {
      id
      base_price
      tax_rate
      cd_rate
      total_price
      status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updatePayment = /* GraphQL */ `
  mutation UpdatePayment(
    $input: UpdatePaymentInput!
    $condition: ModelPaymentConditionInput
  ) {
    updatePayment(input: $input, condition: $condition) {
      id
      base_price
      tax_rate
      cd_rate
      total_price
      status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deletePayment = /* GraphQL */ `
  mutation DeletePayment(
    $input: DeletePaymentInput!
    $condition: ModelPaymentConditionInput
  ) {
    deletePayment(input: $input, condition: $condition) {
      id
      base_price
      tax_rate
      cd_rate
      total_price
      status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createBookingType = /* GraphQL */ `
  mutation CreateBookingType(
    $input: CreateBookingTypeInput!
    $condition: ModelBookingTypeConditionInput
  ) {
    createBookingType(input: $input, condition: $condition) {
      id
      booking_type
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateBookingType = /* GraphQL */ `
  mutation UpdateBookingType(
    $input: UpdateBookingTypeInput!
    $condition: ModelBookingTypeConditionInput
  ) {
    updateBookingType(input: $input, condition: $condition) {
      id
      booking_type
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteBookingType = /* GraphQL */ `
  mutation DeleteBookingType(
    $input: DeleteBookingTypeInput!
    $condition: ModelBookingTypeConditionInput
  ) {
    deleteBookingType(input: $input, condition: $condition) {
      id
      booking_type
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createBooking = /* GraphQL */ `
  mutation CreateBooking(
    $input: CreateBookingInput!
    $condition: ModelBookingConditionInput
  ) {
    createBooking(input: $input, condition: $condition) {
      id
      checkin_date
      checkout_date
      status
      user_id
      BookingType {
        id
        booking_type
        createdAt
        updatedAt
        __typename
      }
      Rooms {
        nextToken
        __typename
      }
      Payment {
        id
        base_price
        tax_rate
        cd_rate
        total_price
        status
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      bookingBookingTypeId
      bookingPaymentId
      __typename
    }
  }
`;
export const updateBooking = /* GraphQL */ `
  mutation UpdateBooking(
    $input: UpdateBookingInput!
    $condition: ModelBookingConditionInput
  ) {
    updateBooking(input: $input, condition: $condition) {
      id
      checkin_date
      checkout_date
      status
      user_id
      BookingType {
        id
        booking_type
        createdAt
        updatedAt
        __typename
      }
      Rooms {
        nextToken
        __typename
      }
      Payment {
        id
        base_price
        tax_rate
        cd_rate
        total_price
        status
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      bookingBookingTypeId
      bookingPaymentId
      __typename
    }
  }
`;
export const deleteBooking = /* GraphQL */ `
  mutation DeleteBooking(
    $input: DeleteBookingInput!
    $condition: ModelBookingConditionInput
  ) {
    deleteBooking(input: $input, condition: $condition) {
      id
      checkin_date
      checkout_date
      status
      user_id
      BookingType {
        id
        booking_type
        createdAt
        updatedAt
        __typename
      }
      Rooms {
        nextToken
        __typename
      }
      Payment {
        id
        base_price
        tax_rate
        cd_rate
        total_price
        status
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      bookingBookingTypeId
      bookingPaymentId
      __typename
    }
  }
`;
export const createRoom = /* GraphQL */ `
  mutation CreateRoom(
    $input: CreateRoomInput!
    $condition: ModelRoomConditionInput
  ) {
    createRoom(input: $input, condition: $condition) {
      id
      room_type
      hotel_id
      Bookings {
        nextToken
        __typename
      }
      PriceDetails {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateRoom = /* GraphQL */ `
  mutation UpdateRoom(
    $input: UpdateRoomInput!
    $condition: ModelRoomConditionInput
  ) {
    updateRoom(input: $input, condition: $condition) {
      id
      room_type
      hotel_id
      Bookings {
        nextToken
        __typename
      }
      PriceDetails {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteRoom = /* GraphQL */ `
  mutation DeleteRoom(
    $input: DeleteRoomInput!
    $condition: ModelRoomConditionInput
  ) {
    deleteRoom(input: $input, condition: $condition) {
      id
      room_type
      hotel_id
      Bookings {
        nextToken
        __typename
      }
      PriceDetails {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createHotel = /* GraphQL */ `
  mutation CreateHotel(
    $input: CreateHotelInput!
    $condition: ModelHotelConditionInput
  ) {
    createHotel(input: $input, condition: $condition) {
      id
      name
      address
      phone_number
      Rooms {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateHotel = /* GraphQL */ `
  mutation UpdateHotel(
    $input: UpdateHotelInput!
    $condition: ModelHotelConditionInput
  ) {
    updateHotel(input: $input, condition: $condition) {
      id
      name
      address
      phone_number
      Rooms {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteHotel = /* GraphQL */ `
  mutation DeleteHotel(
    $input: DeleteHotelInput!
    $condition: ModelHotelConditionInput
  ) {
    deleteHotel(input: $input, condition: $condition) {
      id
      name
      address
      phone_number
      Rooms {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createUserRole = /* GraphQL */ `
  mutation CreateUserRole(
    $input: CreateUserRoleInput!
    $condition: ModelUserRoleConditionInput
  ) {
    createUserRole(input: $input, condition: $condition) {
      id
      role_name
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUserRole = /* GraphQL */ `
  mutation UpdateUserRole(
    $input: UpdateUserRoleInput!
    $condition: ModelUserRoleConditionInput
  ) {
    updateUserRole(input: $input, condition: $condition) {
      id
      role_name
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteUserRole = /* GraphQL */ `
  mutation DeleteUserRole(
    $input: DeleteUserRoleInput!
    $condition: ModelUserRoleConditionInput
  ) {
    deleteUserRole(input: $input, condition: $condition) {
      id
      role_name
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      user_alias
      name
      date_of_birth
      UserRole {
        id
        role_name
        createdAt
        updatedAt
        __typename
      }
      Bookings {
        nextToken
        __typename
      }
      created_by
      last_updated_by
      createdAt
      updatedAt
      userUserRoleId
      __typename
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      user_alias
      name
      date_of_birth
      UserRole {
        id
        role_name
        createdAt
        updatedAt
        __typename
      }
      Bookings {
        nextToken
        __typename
      }
      created_by
      last_updated_by
      createdAt
      updatedAt
      userUserRoleId
      __typename
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      user_alias
      name
      date_of_birth
      UserRole {
        id
        role_name
        createdAt
        updatedAt
        __typename
      }
      Bookings {
        nextToken
        __typename
      }
      created_by
      last_updated_by
      createdAt
      updatedAt
      userUserRoleId
      __typename
    }
  }
`;
export const createRoomBooking = /* GraphQL */ `
  mutation CreateRoomBooking(
    $input: CreateRoomBookingInput!
    $condition: ModelRoomBookingConditionInput
  ) {
    createRoomBooking(input: $input, condition: $condition) {
      id
      bookingId
      roomId
      booking {
        id
        checkin_date
        checkout_date
        status
        user_id
        createdAt
        updatedAt
        bookingBookingTypeId
        bookingPaymentId
        __typename
      }
      room {
        id
        room_type
        hotel_id
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateRoomBooking = /* GraphQL */ `
  mutation UpdateRoomBooking(
    $input: UpdateRoomBookingInput!
    $condition: ModelRoomBookingConditionInput
  ) {
    updateRoomBooking(input: $input, condition: $condition) {
      id
      bookingId
      roomId
      booking {
        id
        checkin_date
        checkout_date
        status
        user_id
        createdAt
        updatedAt
        bookingBookingTypeId
        bookingPaymentId
        __typename
      }
      room {
        id
        room_type
        hotel_id
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteRoomBooking = /* GraphQL */ `
  mutation DeleteRoomBooking(
    $input: DeleteRoomBookingInput!
    $condition: ModelRoomBookingConditionInput
  ) {
    deleteRoomBooking(input: $input, condition: $condition) {
      id
      bookingId
      roomId
      booking {
        id
        checkin_date
        checkout_date
        status
        user_id
        createdAt
        updatedAt
        bookingBookingTypeId
        bookingPaymentId
        __typename
      }
      room {
        id
        room_type
        hotel_id
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
