/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getAddonServicePrice = /* GraphQL */ `
  query GetAddonServicePrice($id: ID!) {
    getAddonServicePrice(id: $id) {
      id
      service_name
      price
      created_by
      last_updated_by
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAddonServicePrices = /* GraphQL */ `
  query ListAddonServicePrices(
    $filter: ModelAddonServicePriceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAddonServicePrices(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        service_name
        price
        created_by
        last_updated_by
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCDRate = /* GraphQL */ `
  query GetCDRate($id: ID!) {
    getCDRate(id: $id) {
      id
      slab_start_price
      slab_end_price
      cd_rate
      is_active
      created_by
      last_updated_by
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCDRates = /* GraphQL */ `
  query ListCDRates(
    $filter: ModelCDRateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCDRates(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        slab_start_price
        slab_end_price
        cd_rate
        is_active
        created_by
        last_updated_by
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTaxRate = /* GraphQL */ `
  query GetTaxRate($id: ID!) {
    getTaxRate(id: $id) {
      id
      slab_start_price
      slab_end_price
      tax_rate
      is_active
      created_by
      last_updated_by
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listTaxRates = /* GraphQL */ `
  query ListTaxRates(
    $filter: ModelTaxRateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTaxRates(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        slab_start_price
        slab_end_price
        tax_rate
        is_active
        created_by
        last_updated_by
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPromoCode = /* GraphQL */ `
  query GetPromoCode($id: ID!) {
    getPromoCode(id: $id) {
      id
      start_date
      end_date
      discount_percentage
      created_by
      last_updated_by
      promo_code
      is_active
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listPromoCodes = /* GraphQL */ `
  query ListPromoCodes(
    $filter: ModelPromoCodeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPromoCodes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        start_date
        end_date
        discount_percentage
        created_by
        last_updated_by
        promo_code
        is_active
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPriceType = /* GraphQL */ `
  query GetPriceType($id: ID!) {
    getPriceType(id: $id) {
      id
      price_type
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listPriceTypes = /* GraphQL */ `
  query ListPriceTypes(
    $filter: ModelPriceTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPriceTypes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        price_type
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPriceDetail = /* GraphQL */ `
  query GetPriceDetail($id: ID!) {
    getPriceDetail(id: $id) {
      id
      start_date
      end_date
      base_price
      is_active
      created_by
      last_updated_by
      PriceType {
        id
        price_type
        createdAt
        updatedAt
        __typename
      }
      roomID
      createdAt
      updatedAt
      priceDetailPriceTypeId
      __typename
    }
  }
`;
export const listPriceDetails = /* GraphQL */ `
  query ListPriceDetails(
    $filter: ModelPriceDetailFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPriceDetails(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        start_date
        end_date
        base_price
        is_active
        created_by
        last_updated_by
        roomID
        createdAt
        updatedAt
        priceDetailPriceTypeId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const priceDetailsByRoomID = /* GraphQL */ `
  query PriceDetailsByRoomID(
    $roomID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelPriceDetailFilterInput
    $limit: Int
    $nextToken: String
  ) {
    priceDetailsByRoomID(
      roomID: $roomID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        start_date
        end_date
        base_price
        is_active
        created_by
        last_updated_by
        roomID
        createdAt
        updatedAt
        priceDetailPriceTypeId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPayment = /* GraphQL */ `
  query GetPayment($id: ID!) {
    getPayment(id: $id) {
      id
      base_price
      tax_rate
      cd_rate
      total_price
      status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listPayments = /* GraphQL */ `
  query ListPayments(
    $filter: ModelPaymentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPayments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        base_price
        tax_rate
        cd_rate
        total_price
        status
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getBookingType = /* GraphQL */ `
  query GetBookingType($id: ID!) {
    getBookingType(id: $id) {
      id
      booking_type
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listBookingTypes = /* GraphQL */ `
  query ListBookingTypes(
    $filter: ModelBookingTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBookingTypes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        booking_type
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getBooking = /* GraphQL */ `
  query GetBooking($id: ID!) {
    getBooking(id: $id) {
      id
      checkin_date
      checkout_date
      status
      user_id
      BookingType {
        id
        booking_type
        createdAt
        updatedAt
        __typename
      }
      Rooms {
        nextToken
        __typename
      }
      Payment {
        id
        base_price
        tax_rate
        cd_rate
        total_price
        status
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      bookingBookingTypeId
      bookingPaymentId
      __typename
    }
  }
`;
export const listBookings = /* GraphQL */ `
  query ListBookings(
    $filter: ModelBookingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBookings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        checkin_date
        checkout_date
        status
        user_id
        createdAt
        updatedAt
        bookingBookingTypeId
        bookingPaymentId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const bookingsByUser_id = /* GraphQL */ `
  query BookingsByUser_id(
    $user_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelBookingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    bookingsByUser_id(
      user_id: $user_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        checkin_date
        checkout_date
        status
        user_id
        createdAt
        updatedAt
        bookingBookingTypeId
        bookingPaymentId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getRoom = /* GraphQL */ `
  query GetRoom($id: ID!) {
    getRoom(id: $id) {
      id
      room_type
      hotel_id
      Bookings {
        nextToken
        __typename
      }
      PriceDetails {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listRooms = /* GraphQL */ `
  query ListRooms(
    $filter: ModelRoomFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRooms(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        room_type
        hotel_id
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const roomsByHotel_id = /* GraphQL */ `
  query RoomsByHotel_id(
    $hotel_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelRoomFilterInput
    $limit: Int
    $nextToken: String
  ) {
    roomsByHotel_id(
      hotel_id: $hotel_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        room_type
        hotel_id
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getHotel = /* GraphQL */ `
  query GetHotel($id: ID!) {
    getHotel(id: $id) {
      id
      name
      address
      phone_number
      Rooms {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listHotels = /* GraphQL */ `
  query ListHotels(
    $filter: ModelHotelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHotels(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        address
        phone_number
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUserRole = /* GraphQL */ `
  query GetUserRole($id: ID!) {
    getUserRole(id: $id) {
      id
      role_name
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listUserRoles = /* GraphQL */ `
  query ListUserRoles(
    $filter: ModelUserRoleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserRoles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        role_name
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      user_alias
      name
      date_of_birth
      UserRole {
        id
        role_name
        createdAt
        updatedAt
        __typename
      }
      Bookings {
        nextToken
        __typename
      }
      created_by
      last_updated_by
      createdAt
      updatedAt
      userUserRoleId
      __typename
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        user_alias
        name
        date_of_birth
        created_by
        last_updated_by
        createdAt
        updatedAt
        userUserRoleId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getRoomBooking = /* GraphQL */ `
  query GetRoomBooking($id: ID!) {
    getRoomBooking(id: $id) {
      id
      bookingId
      roomId
      booking {
        id
        checkin_date
        checkout_date
        status
        user_id
        createdAt
        updatedAt
        bookingBookingTypeId
        bookingPaymentId
        __typename
      }
      room {
        id
        room_type
        hotel_id
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listRoomBookings = /* GraphQL */ `
  query ListRoomBookings(
    $filter: ModelRoomBookingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRoomBookings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        bookingId
        roomId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const roomBookingsByBookingId = /* GraphQL */ `
  query RoomBookingsByBookingId(
    $bookingId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelRoomBookingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    roomBookingsByBookingId(
      bookingId: $bookingId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        bookingId
        roomId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const roomBookingsByRoomId = /* GraphQL */ `
  query RoomBookingsByRoomId(
    $roomId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelRoomBookingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    roomBookingsByRoomId(
      roomId: $roomId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        bookingId
        roomId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
