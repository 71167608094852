import React, { useState } from "react";
import "./css/StayBooking.css";
import { Loader } from "@aws-amplify/ui-react";
import LogoSection from "../commons/LogoSection";
import { useSelector } from "react-redux";
import { addDays, differenceInDays, format, set } from "date-fns";
import { DayPicker } from "react-day-picker";
import "./css/DayPicker.css";
import { Alert, Table } from "react-bootstrap";
import { post } from "aws-amplify/api";

const StayBooking = () => {
  const [isLoading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const appCommonSelector = (state) => state.appCommon;
  const appCommonData = useSelector(appCommonSelector);

  // Booking inputs
  const [selectedDateRange, setSelectedDateRange] = useState(null);
  const [numberOfDays, setNumberOfDays] = useState(null);

  const isValidPriceCalculationInputs = () => {
    if (!selectedDateRange) {
      setErrorMessage("Date(s) not selected. Please select the date(s).");
      return false;
    }
    return true;
  };

  const getPriceCalculation = async () => {
    setErrorMessage("");
    if (isValidPriceCalculationInputs()) {
      setLoading(true);
      try {
        /*const restOperation = post({
          apiName: "KalyaanaVeeduRESTApi",
          path: "/v1/bookingprice",
          options: {
            body: {
              fromDate: selectedDateRange.from ? selectedDateRange.from : "",
              toDate: selectedDateRange.to ? selectedDateRange.from : "",
            },
          },
        });
        const response = await restOperation.response;
        console.log(response);
        console.log("GET call success: ", response);
        */
      } catch (error) {
        console.log("GET called failed: ", error);
      } finally {
        setLoading(false);
      }
    }
  };

  let footer = (
    <h6 style={{ textAlign: "center" }}>
      {appCommonData.appLanguage === "English"
        ? "Please select the dates."
        : "தேதிகளைத் தேர்ந்தெடுக்கவும். "}
    </h6>
  );
  if (selectedDateRange?.from) {
    if (!selectedDateRange.to) {
      footer = (
        <>
          <h6 style={{ textAlign: "center", color: "#860505" }}>
            {appCommonData.appLanguage === "English"
              ? "Booking from : "
              : "முன்பதிவு தொடங்குவது : "}
            {format(selectedDateRange.from, "dd-MMM-yyyy")} {" 12:00 PM."}
          </h6>
          <h6 style={{ textAlign: "center", color: "#860505" }}>
            {appCommonData.appLanguage === "English"
              ? "Booking till  : "
              : "முன்பதிவு முடிவது : "}
            {format(addDays(selectedDateRange.from, 1), "dd-MMM-yyyy")}{" "}
            {" 11:00 AM."}
          </h6>
        </>
      );
    } else if (selectedDateRange.to) {
      footer = (
        <>
          <h6 style={{ textAlign: "center", color: "#860505" }}>
            {appCommonData.appLanguage === "English"
              ? "Booking from : "
              : "முன்பதிவு தொடங்குவது : "}
            {format(selectedDateRange.from, "dd-MMM-yyyy")} {" 12:00 PM."}
          </h6>
          <h6 style={{ textAlign: "center", color: "#860505" }}>
            {appCommonData.appLanguage === "English"
              ? "Booking till  : "
              : "முன்பதிவு முடிவது : "}
            {format(addDays(selectedDateRange.to, 1), "dd-MMM-yyyy")}{" "}
            {" 11:00 AM."}
          </h6>
        </>
      );
    }
  }

  return (
    <>
      <div className="book-a-stay-top-container">
        <div className="book-a-stay-main-container">
          <LogoSection />
          <h6
            style={{
              fontFamily: "Merriweather",
              margin: "2%",
              textAlign: "center",
            }}
          >
            {appCommonData.appLanguage === "English"
              ? "Book your stay"
              : "தங்குவதற்கு முன்பதிவு செய்யுங்கள்"}
          </h6>
          <Alert variant="warning">
            {appCommonData.appLanguage === "English"
              ? "Currently, we allow only full house bookings. Individual Room bookings will begin from <<DD-MM-YYYY>>"
              : "தற்போது, ​​முழு வீடு முன்பதிவுகளை மட்டுமே அனுமதிக்கிறோம். தனிப்பட்ட அறை முன்பதிவுகள் <<DD-MM-YYYY>> தேதியிலிருந்து தொடங்கும்"}
          </Alert>
          <DayPicker
            mode="range"
            selected={selectedDateRange}
            onSelect={setSelectedDateRange}
            //Limiting Month Navigation to 20 days for beta testing
            fromDate={new Date()}
            toDate={addDays(new Date(), 20)}
            //Limiting minimum to 1 days and maximum to 5 days for beta testing
            max={5}
            //Footer
            footer={footer}
          />
          <button
            onClick={getPriceCalculation}
            className="book-a-stay-main-container-button"
            type="button"
          >
            {appCommonData.appLanguage === "English"
              ? "Get Price"
              : "விலை கணக்கிடு"}{" "}
          </button>

          {/* selectedDateRange && (
            <Table>
              <tr>
                <th>Description</th>
                <th>Price</th>
              </tr>
              <tr>
                <td>Base Price</td>
                <td>10</td>
              </tr>
              <tr>
                <td>GST (18%)</td>
                <td>1.8</td>
              </tr>
              <tr>
                <td>Caution Deposit (25% of base price) </td>
                <td>2.5</td>
              </tr>
              <tr>
                <th>Total</th>
                <th>14.3</th>
              </tr>
            </Table>
            //test commit
          ) */}

          {isLoading && (
            <div className="book-a-stay-loader-container">
              <Loader variation="linear" filledColor="brown" />
            </div>
          )}
          {errorMessage && (
            <div className="error-messages-container">
              <h6>{errorMessage}</h6>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default StayBooking;
