import React from "react";

import Photo1 from "./../../assets/images/rooms/rooma.jpeg";
import Photo2 from "./../../assets/images/rooms/roomb.jpeg";
import Photo3 from "./../../assets/images/rooms/roomc.jpeg";
import Photo4 from "./../../assets/images/rooms/roomd.jpeg";

const RoomGrid = () => {
  const images = [Photo1, Photo2, Photo3, Photo4];
  return (
    <div className="image-grid">
      {images.map((image, index) => (
        <div key={index}>
          <img
            src={image}
            alt={`rooms ${index + 1}`}
            style={{ width: "100%", height: "auto" }}
          />
        </div>
      ))}
      <style>
        {`
          .image-grid {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 16px;
            width: 75%;
          }

          @media (max-width: 767px) {
            .image-grid {
              grid-template-columns: 1fr;
            }
          }

          .image-item {
            margin-bottom: 16px;
          }
        `}
      </style>
    </div>
  );
};

export default RoomGrid;
