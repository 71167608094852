import React, { useState } from "react";
import { useSelector } from "react-redux";
import "./css/PromoCodesManager.css";
import LogoSection from "../commons/LogoSection";
import { DayPicker } from "react-day-picker";
import { addDays, format } from "date-fns";
import { Loader } from "@aws-amplify/ui-react";
import { generateClient } from "aws-amplify/api";
import { createPromoCode, updatePromoCode } from "../../graphql/mutations";
import { getCurrentUser } from "aws-amplify/auth";
import { listPromoCodes } from "../../graphql/queries";
import { Table } from "react-bootstrap";

const PromoCodesManager = () => {
  const appCommonSelector = (state) => state.appCommon;
  const appCommonData = useSelector(appCommonSelector);
  const [activeSection, setActiveSection] = useState("add");

  //DB Client
  const client = generateClient();

  // Add new Promocode inputs
  const [newPromocode, setNewPromocode] = useState("");
  const [discountPercentage, setDiscountPercentage] = useState("");
  const [selectedDateRange, setSelectedDateRange] = useState(null);

  // common hooks
  const [responseStatus, setResponseStatus] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [isLoading, setLoading] = useState(false);

  // All Promocodes
  const [allPromoCodes, setAllPromoCodes] = useState(null);

  // Deactivate Promocode
  const [isFieldUpdated, setFieldUpdated] = useState(false);

  const fetchAllPromocodes = async () => {
    try {
      setLoading(true);
      if (!allPromoCodes || isFieldUpdated) {
        const response = await client.graphql({ query: listPromoCodes });
        setAllPromoCodes(response.data.listPromoCodes.items);
      }
      setResponseStatus("success");
      setResponseMessage("Promocodes were fetched successfully.");
    } catch (error) {
      setResponseStatus("error");
      setResponseMessage("Error occurred while fetching promocodes.");
      console.error(
        "Error occurred while fetching promocodes." + error.message
      );
    } finally {
      setFieldUpdated(false);
      setLoading(false);
    }
  };

  const isValidCouponcode = () => {
    // Promocode will have 4 to 10 upper case characters and 1-2 digit number indicating percentage. Example LUCKY10, HAPPY5
    const regex = /^[A-Z]{4,10}[0-9]{1,2}$/;
    return regex.test(newPromocode);
  };

  const isValidAddPromocodeInputs = () => {
    if (!isValidCouponcode()) {
      setResponseStatus("error");
      setResponseMessage("Invalid Promocode.");
      return false;
    }

    //Discount Percentage should be between 1 to 30. check with owner
    const parsedDiscoutPercentage = parseFloat(discountPercentage);
    if (!(parsedDiscoutPercentage >= 1 && parsedDiscoutPercentage <= 25)) {
      setResponseStatus("error");
      setResponseMessage("Discount Percentage should be between 1 to 25.");
      return false;
    }

    // start date and end date validation.
    if (!selectedDateRange) {
      setResponseStatus("error");
      setResponseMessage("Date(s) cannot be empty.");
      return false;
    }

    return true;
  };

  const getParsedDate = (inputDate) => {
    return new Date(inputDate).toISOString();
  };

  const handleAddPromocode = async () => {
    setResponseStatus("");
    setResponseMessage("");
    try {
      setLoading(true);
      if (isValidAddPromocodeInputs()) {
        const { userId } = await getCurrentUser();
        const newPromoCodeInputs = {
          start_date: getParsedDate(selectedDateRange.from),
          end_date: getParsedDate(
            selectedDateRange.to
              ? addDays(selectedDateRange.to, 1)
              : addDays(selectedDateRange.from, 1)
          ),
          discount_percentage: parseFloat(discountPercentage),
          created_by: userId,
          last_updated_by: userId,
          promo_code: newPromocode,
          is_active: true,
        };
        const newlyAddedPromocode = await client.graphql({
          query: createPromoCode.replaceAll("__typename", ""),
          variables: { input: newPromoCodeInputs },
        });
        setResponseStatus("success");
        setResponseMessage(
          newlyAddedPromocode.data.createPromoCode.promo_code +
            " is added successfully"
        );
        setFieldUpdated(true);
        resetInputs();
      }
    } catch (error) {
      setResponseStatus("error");
      setResponseMessage(
        "Error occurred while adding promocode. Refer console for more details."
      );
      console.error("Error occurred while adding promocode : ", error.message);
    } finally {
      setLoading(false);
    }
  };

  const resetInputs = () => {
    setNewPromocode("");
    setDiscountPercentage("");
    setSelectedDateRange(null);
  };

  const deactivatePromocode = async (id) => {
    try {
      setLoading(true);
      const updateDetails = {
        id: id,
        is_active: false,
      };
      const updatedPromocode = await client.graphql({
        query: updatePromoCode,
        variables: { input: updateDetails },
      });

      setResponseStatus("success");
      setResponseMessage(
        "Promocode " +
          updatedPromocode.data.updatePromoCode.promo_code +
          " was deactivated successfully. Click Refresh to reload."
      );
      setFieldUpdated(true);
    } catch (error) {
      setResponseStatus("error");
      setResponseMessage(
        "Error occurred while deactivating Promocode. Refer console."
      );
      console.error(
        "Error occurred while deactivating Promoocde" + error.message
      );
    } finally {
      setLoading(false);
    }
  };

  let footer = (
    <h6 style={{ textAlign: "center" }}>
      {appCommonData.appLanguage === "English"
        ? "Please select the dates."
        : "தேதிகளைத் தேர்ந்தெடுக்கவும். "}
    </h6>
  );
  if (selectedDateRange?.from) {
    if (!selectedDateRange.to) {
      footer = (
        <>
          <h6 style={{ textAlign: "center", color: "#860505" }}>
            {appCommonData.appLanguage === "English"
              ? "Start Date : "
              : "தொடக்க தேதி : "}
            {format(selectedDateRange.from, "dd-MMM-yyyy")}
          </h6>
          <h6 style={{ textAlign: "center", color: "#860505" }}>
            {appCommonData.appLanguage === "English"
              ? "End Date  : "
              : "முடிவு தேதி : "}
            {format(selectedDateRange.from, "dd-MMM-yyyy")}
          </h6>
        </>
      );
    } else if (selectedDateRange.to) {
      footer = (
        <>
          <h6 style={{ textAlign: "center", color: "#860505" }}>
            {appCommonData.appLanguage === "English"
              ? "Start Date : "
              : "தொடக்க தேதி : "}
            {format(selectedDateRange.from, "dd-MMM-yyyy")}
          </h6>
          <h6 style={{ textAlign: "center", color: "#860505" }}>
            {appCommonData.appLanguage === "English"
              ? "End Date  : "
              : "முடிவு தேதி : "}
            {format(selectedDateRange.to, "dd-MMM-yyyy")}
          </h6>
        </>
      );
    }
  }

  return (
    <div className="promocodes-top-container">
      <div className="promocodes-manage-container">
        <div className="promocodes-toggle-buttons">
          <button
            className={activeSection === "add" ? "active" : ""}
            onClick={() => {
              resetInputs();
              setResponseStatus("");
              setResponseMessage("");
              setActiveSection("add");
            }}
            type="button"
          >
            {appCommonData.appLanguage === "English"
              ? "Add Promocode"
              : "ப்ரோமோகோடு சேர்க்க"}
          </button>
          <button
            className={activeSection === "manage" ? "active" : ""}
            onClick={() => {
              resetInputs();
              setResponseStatus("");
              setResponseMessage("");
              setActiveSection("manage");
              fetchAllPromocodes();
            }}
            type="button"
          >
            {appCommonData.appLanguage === "English"
              ? "View / Update Promocode"
              : "ப்ரோமோகோடைப் பார்க்க / புதுப்பிக்க"}
          </button>
        </div>
        <LogoSection />

        {activeSection === "add" && (
          <div className="add-promocode-form">
            <h6>
              {appCommonData.appLanguage === "English"
                ? "Add New Promocode"
                : "புதிய விளம்பர குறியீட்டைச் சேர்க்க"}
            </h6>
            <label>
              {appCommonData.appLanguage === "English"
                ? "Promocode"
                : "ப்ரோமோகோடு"}
              <input
                type="text"
                value={newPromocode}
                onChange={(e) => setNewPromocode(e.target.value)}
              />
            </label>
            <label>
              {appCommonData.appLanguage === "English"
                ? "Discount Percentage"
                : "தள்ளுபடி சதவீதம்"}
              <input
                type="number"
                value={discountPercentage}
                onChange={(e) => setDiscountPercentage(e.target.value)}
              />
            </label>
            <label>
              {appCommonData.appLanguage === "English"
                ? "Select Start and End Date for Promocode"
                : "ப்ரோமோகோடு தொடக்க மற்றும் முடிவு தேதியைத் தேர்ந்தெடுக்கவும்  "}
            </label>
            <DayPicker
              mode="range"
              selected={selectedDateRange}
              onSelect={setSelectedDateRange}
              fromDate={new Date()}
              footer={footer}
            />
            <button
              type="button"
              id="add-promocode-button"
              onClick={() => {
                handleAddPromocode();
              }}
            >
              {appCommonData.appLanguage === "English"
                ? "Add New Promocode"
                : "ப்ரோமோகோடு சேர்"}
            </button>
          </div>
        )}

        {activeSection === "manage" && (
          <div className="view-promocodes-section">
            <h6>
              {appCommonData.appLanguage === "English"
                ? "All Promocodes"
                : "ப்ரோமோகோடு"}
            </h6>
            <button type="button" onClick={() => fetchAllPromocodes()}>
              Refresh
            </button>
            {allPromoCodes && (
              <Table striped bordered hover responsive="sm">
                <thead>
                  <tr>
                    <th>Promocode</th>
                    <th>Discount</th>
                    <th>Start Date</th>
                    <th>End Date</th>
                    <th>Status</th>
                    <th>Deactivate</th>
                  </tr>
                </thead>
                <tbody>
                  {allPromoCodes.map((item) => (
                    <tr key={item.id}>
                      <td>{item.promo_code}</td>
                      <td>{item.discount_percentage}</td>
                      <td>{format(item.start_date, "yyyy-MM-dd hh:mm:ss")}</td>
                      <td>{format(item.end_date, "yyyy-MM-dd hh:mm:ss")}</td>
                      <td>{item.is_active ? "Active" : "Inactive"}</td>
                      <td>
                        {item.is_active ? (
                          <button
                            type="button"
                            onClick={() => {
                              deactivatePromocode(item.id);
                            }}
                          >
                            Deactivate
                          </button>
                        ) : (
                          ""
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
          </div>
        )}
        {isLoading && (
          <div>
            <Loader variation="linear" filledColor="brown" />
          </div>
        )}
        {responseMessage && (
          <div className="response-messages-container">
            <h6
              style={{ color: responseStatus === "success" ? "green" : "red" }}
            >
              {responseMessage}
            </h6>
          </div>
        )}
      </div>
    </div>
  );
};

export default PromoCodesManager;
