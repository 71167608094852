import { NavDropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { handleNavItemSelect } from "../../reducers/app-common/actions";
import { signOut } from "aws-amplify/auth";

const MyProfileDropDown = () => {
  const appCommonSelector = (state) => state.appCommon;
  const appCommonData = useSelector(appCommonSelector);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      dispatch(handleNavItemSelect(false));
      await signOut();
    } catch (error) {
      console.log("Error occurred while signing out : " + error.message);
    } finally {
      navigate("/");
    }
  };

  return (
    <NavDropdown
      title={
        appCommonData.appLanguage === "English" ? "My Profile" : "என் சுயவிவரம்"
      }
      className="my-profile-dropdown"
    >
      <NavDropdown.Item
        as={RouterLink}
        to="/account"
        onClick={(e) => {
          dispatch(handleNavItemSelect(!appCommonData.isNavbarExpanded));
        }}
      >
        {appCommonData.appLanguage === "English" ? "My Account" : "எனது கணக்கு"}
      </NavDropdown.Item>
      <NavDropdown.Item
        as={RouterLink}
        to="/changePassword"
        onClick={(e) => {
          dispatch(handleNavItemSelect(!appCommonData.isNavbarExpanded));
        }}
      >
        {appCommonData.appLanguage === "English"
          ? "Change Password"
          : "கடவுச்சொல்லை மாற்றம்"}
      </NavDropdown.Item>
      {/*NavDropdown.Item onClick={handleNavItemSelect}>
                    {appCommonData.appLanguage === "English"
                      ? "My Bookings"
                      : "எனது முன்பதிவுகள்"}
                  </NavDropdown.Item>  */}
      <NavDropdown.Divider />
      <NavDropdown.Item onClick={handleLogout}>
        {appCommonData.appLanguage === "English" ? "Logout" : "வெளியேறு"}
      </NavDropdown.Item>
    </NavDropdown>
  );
};

export default MyProfileDropDown;
