import { combineReducers } from "redux";
import { AppCommonReducer } from "./app-common/reducer";
import { UserReducer } from "./user/reducer";

export const rootReducer = combineReducers({
  appCommon: AppCommonReducer,
  user: UserReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
