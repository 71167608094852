import React from "react";
import { experienceHeader, experienceHeaderInTamil } from "../../content";
import { useSelector } from "react-redux";
import PhotoSlider from "../commons/PhotoSlider";

import Photo1 from "./../../assets/images/experience/exp1.jpg";
import Photo2 from "./../../assets/images/experience/exp2.jpg";
import Photo3 from "./../../assets/images/experience/exp3.jpg";
import Photo4 from "./../../assets/images/home-slider/courtyard.JPG";
import Photo5 from "./../../assets/images/home-slider/dining.JPG";
import Photo6 from "./../../assets/images/home-slider/bedroom.JPG";
import Photo7 from "./../../assets/images/home-slider/balcony.JPG";

const Experience = () => {
  const appCommonSelector = (state) => state.appCommon;
  const appCommonData = useSelector(appCommonSelector);

  const images = [Photo1, Photo2, Photo3, Photo4, Photo5, Photo6, Photo7];
  return (
    <>
      <div className={appCommonData.appLanguage} id="experience">
        <h1>
          {appCommonData.appLanguage === "English"
            ? experienceHeader
            : experienceHeaderInTamil}
        </h1>
        <PhotoSlider images={images} />
      </div>
    </>
  );
};

export default Experience;
