import React, { useEffect, useState } from "react";
import {
  ourMissionContent,
  ourMissionContentInTamil,
  ourMissionHeader,
  ourMissionHeaderInTamil,
  welcomeContent,
  welcomeContentInTamil,
  welcomeHeader,
  welcomeHeaderInTamil,
  whoWeAreContent,
  whoWeAreContentInTamilOne,
  whoWeAreContentInTamilTwo,
  whoWeAreHeader,
  whoWeAreHeaderInTamil,
} from "../../content";
import { useSelector } from "react-redux";
import Photo1 from "./../../assets/images/ourstory/ourstory1.jpeg";
import Photo2 from "./../../assets/images/ourstory/ourstory2.jpeg";
import "./css/Landing.css";

const OurStory = () => {
  const appCommonSelector = (state) => state.appCommon;
  const appCommonData = useSelector(appCommonSelector);
  const [isVisible, setVisible] = useState(false);

  useEffect(() => {
    setVisible(true);
  }, []);

  return (
    <div id="story" className={appCommonData.appLanguage}>
      <div>
        <h1>
          {appCommonData.appLanguage === "English"
            ? welcomeHeader
            : welcomeHeaderInTamil}
        </h1>
        <p>
          {appCommonData.appLanguage === "English"
            ? welcomeContent
            : welcomeContentInTamil}
        </p>
      </div>
      <div className="container reverse-order">
        <div className="content">
          <div>
            <h3>
              {appCommonData.appLanguage === "English"
                ? whoWeAreHeader
                : whoWeAreHeaderInTamil}
            </h3>

            {appCommonData.appLanguage === "English" ? (
              <p>{whoWeAreContent} </p>
            ) : (
              <h5>{whoWeAreContentInTamilOne}</h5>
            )}
            <p>
              {appCommonData.appLanguage === "English"
                ? ""
                : whoWeAreContentInTamilTwo}
            </p>
          </div>
        </div>
        <div
          className={`image slide-in-from-right-container ${
            isVisible ? "visible" : ""
          }`}
        >
          <img src={Photo1} width="100%" alt="who-we-are-img" />
        </div>
      </div>
      <div className="container">
        <div
          className={`image slide-in-from-left-container ${
            isVisible ? "visible" : ""
          }`}
        >
          <img src={Photo2} width="100%" alt="who-we-are-img" />
        </div>
        <div className="content">
          <div>
            <h3>
              {appCommonData.appLanguage === "English"
                ? ourMissionHeader
                : ourMissionHeaderInTamil}
            </h3>
            <p>
              {appCommonData.appLanguage === "English"
                ? ourMissionContent
                : ourMissionContentInTamil}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurStory;
