import React, { useState } from "react";
import { useSelector } from "react-redux";
import "./css/TaxRateManager.css";
import LogoSection from "../commons/LogoSection";
import { Loader } from "@aws-amplify/ui-react";
import { generateClient } from "aws-amplify/api";
import { createTaxRate } from "../../graphql/mutations";
import { getCurrentUser } from "aws-amplify/auth";
import { listTaxRates } from "../../graphql/queries";
import { Table } from "react-bootstrap";

const TaxRateManager = () => {
  const appCommonSelector = (state) => state.appCommon;
  const appCommonData = useSelector(appCommonSelector);
  const [activeSection, setActiveSection] = useState("add");

  //DB Client
  const client = generateClient();

  // Add new Promocode inputs
  const [slabStartPrice, setSlabStartPrice] = useState("");
  const [slabEndPrice, setSlabEndPrice] = useState("");
  const [taxRate, setTaxRate] = useState("");

  // common hooks
  const [responseStatus, setResponseStatus] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [isLoading, setLoading] = useState(false);

  // All Promocodes
  const [allTaxRates, setAllTaxRates] = useState(null);

  // Deactivate Promocode
  const [isFieldUpdated, setFieldUpdated] = useState(false);

  const fetchAllTaxRates = async () => {
    try {
      setLoading(true);
      if (!allTaxRates || isFieldUpdated) {
        const response = await client.graphql({ query: listTaxRates });
        setAllTaxRates(response.data.listTaxRates.items);
      }
      setResponseStatus("success");
      setResponseMessage("Tax Rates were fetched successfully.");
    } catch (error) {
      setResponseStatus("error");
      setResponseMessage("Error occurred while fetching Tax Rates.");
      console.error("Error occurred while fetching Tax Rates." + error.message);
    } finally {
      setFieldUpdated(false);
      setLoading(false);
    }
  };

  const isValidTaxRateInputs = () => {
    //On researching about tax rates for hotels, I found the following documents.
    // https://cleartax.in/s/impact-of-gst-hospitality-industry

    if (parseFloat(slabStartPrice) < 0 || parseFloat(slabEndPrice) < 0) {
      setResponseStatus("error");
      setResponseMessage(
        "Slab Start Price/Slab End Price shouldn't be less than zero."
      );
      return false;
    }

    if (parseFloat(slabStartPrice) >= parseFloat(slabEndPrice)) {
      setResponseStatus("error");
      setResponseMessage(
        "Slab Start Price should be less than slab end price."
      );
      return false;
    }

    const parsedTaxRate = parseFloat(taxRate);
    if (!(parsedTaxRate > 0 && parsedTaxRate <= 28)) {
      setResponseStatus("error");
      setResponseMessage("Tax Rate Percentage should be between 1 to 28.");
      return false;
    }
    return true;
  };

  const handleAddTaxRate = async () => {
    setResponseStatus("");
    setResponseMessage("");
    try {
      setLoading(true);
      if (isValidTaxRateInputs()) {
        const { userId } = await getCurrentUser();
        const newTaxRateInputs = {
          slab_start_price: parseFloat(slabStartPrice),
          slab_end_price: parseFloat(slabEndPrice),
          tax_rate: parseFloat(taxRate),
          is_active: true,
          created_by: userId,
          last_updated_by: userId,
        };
        await client.graphql({
          query: createTaxRate.replaceAll("__typename", ""),
          variables: { input: newTaxRateInputs },
        });
        setResponseStatus("success");
        setResponseMessage("New Tax Rate is added successfully");
        setFieldUpdated(true);
        resetInputs();
      }
    } catch (error) {
      setResponseStatus("error");
      setResponseMessage(
        "Error occurred while adding tax rate. Refer console."
      );
      console.error("Error occurred while adding tax rate : ", error.message);
    } finally {
      setLoading(false);
    }
  };

  const resetInputs = () => {
    setSlabStartPrice("");
    setSlabEndPrice("");
    setTaxRate("");
  };

  return (
    <div className="taxrates-top-container">
      <div className="taxrates-manage-container">
        <div className="taxrates-toggle-buttons">
          <button
            className={activeSection === "add" ? "active" : ""}
            onClick={() => {
              resetInputs();
              setResponseStatus("");
              setResponseMessage("");
              setActiveSection("add");
            }}
            type="button"
          >
            {appCommonData.appLanguage === "English"
              ? "Add New Tax Rate"
              : "புதிய வரி விகிதத்தைச் சேர்க்க"}
          </button>
          <button
            className={activeSection === "manage" ? "active" : ""}
            onClick={() => {
              resetInputs();
              setResponseStatus("");
              setResponseMessage("");
              setActiveSection("manage");
              fetchAllTaxRates();
            }}
            type="button"
          >
            {appCommonData.appLanguage === "English"
              ? "View/Update All Tax Rates"
              : "அனைத்து வரி விகிதங்களையும் பார்க்க"}
          </button>
        </div>
        <LogoSection />

        {activeSection === "add" && (
          <div className="add-taxrate-form">
            <h6>
              {appCommonData.appLanguage === "English"
                ? "Add New Tax Rate"
                : "புதிய வரி விகிதத்தைச் சேர்க்க"}
            </h6>
            <label>
              {appCommonData.appLanguage === "English"
                ? "Slab Start Price"
                : "ஸ்லாப் தொடக்க விலை"}
              <input
                type="number"
                value={slabStartPrice}
                onChange={(e) => setSlabStartPrice(e.target.value)}
              />
            </label>
            <label>
              {appCommonData.appLanguage === "English"
                ? "Slab End Price"
                : "ஸ்லாப் முடிவு விலை"}
              <input
                type="number"
                value={slabEndPrice}
                onChange={(e) => setSlabEndPrice(e.target.value)}
              />
            </label>
            <label>
              {appCommonData.appLanguage === "English"
                ? "Tax Rate"
                : "வரி விகிதம்"}
              <input
                type="number"
                value={taxRate}
                onChange={(e) => setTaxRate(e.target.value)}
              />
            </label>
            <button
              type="button"
              id="add-taxrate-button"
              onClick={() => {
                handleAddTaxRate();
              }}
            >
              {appCommonData.appLanguage === "English"
                ? "Add new tax rate"
                : "புதிய வரி விகிதத்தைச் சேர்"}
            </button>
          </div>
        )}
        {activeSection === "manage" && (
          <div className="view-taxrates-section">
            <h6>
              {appCommonData.appLanguage === "English"
                ? "All Tax Slabs"
                : "அனைத்து வரி விகிதங்கள்"}
            </h6>
            <button type="button" onClick={() => fetchAllTaxRates()}>
              Refresh
            </button>
            {allTaxRates && (
              <Table striped bordered hover responsive="sm">
                <thead>
                  <tr>
                    <th>Slab Start Price</th>
                    <th>Slab End Price</th>
                    <th>Tax Rate</th>
                    <th>Status</th>
                    <th>Update Tax Slab</th>
                    <th>Deactivate Tax Slab</th>
                  </tr>
                </thead>
                <tbody>
                  {allTaxRates.map((item) => (
                    <tr key={item.id}>
                      <td>{item.slab_start_price}</td>
                      <td>{item.slab_end_price}</td>
                      <td>{item.tax_rate}</td>
                      <td>{item.is_active ? "Active" : "Deactivate"}</td>
                      <td>
                        <button type="button">Update</button>
                      </td>
                      <td>
                        <button type="button">Deactivate</button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
          </div>
        )}
        {isLoading && (
          <div>
            <Loader variation="linear" filledColor="brown" />
          </div>
        )}
        {responseMessage && (
          <div className="response-messages-container">
            <h6
              style={{ color: responseStatus === "success" ? "green" : "red" }}
            >
              {responseMessage}
            </h6>
          </div>
        )}
      </div>
    </div>
  );
};

export default TaxRateManager;
